// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import {
  HomeHeader,
  AppButton,
  AddPost,
  AppInput,
  Post,
  AddStats
} from "../../components"
import { Element } from "react-scroll"
import { Divider, Grid, Box } from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import Postimage from "../../assets/images/dashboard.jpg"
import { ReactComponent as LocationOnGrey } from "../../assets/svg/location_on_grey.svg"
import { ReactComponent as LikeIcon } from "../../assets/svg/Like.svg"
import { ReactComponent as MessageIcon } from "../../assets/svg/Message.svg"
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg"
import { ReactComponent as InstaIcon } from "../../assets/svg/insta.svg"
import { ReactComponent as SettingWhiteIcon } from "../../assets/svg/settingWhite.svg"
import { ReactComponent as SendIcon } from "../../assets/svg/Send.svg"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import AddIcon from "@mui/icons-material/Add"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  acceptLink,
  addComments,
  declineLink,
  getAllPosts,
  getChannels,
  getComments,
  getPostById,
  postLike,
  searchUserProfile,
  searchUserProfileOld
} from "../../api/auth"
import { getError } from "../../utils/getError"
import LinkPlayer from "../../components/GeneralModal/LinkPlayer"
import { getUsers } from "../../api/admin"
import NotFoundPage from "../../assets/images/404-Page.png"

export default function DetailPost({ }) {
  const { enqueueSnackbar } = useSnackbar()
  const guestRef = useRef(null)
  const navigate = useNavigate()
  // for link action
  const { actionType } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const linkId = queryParams.get("link_id")
  const { fetchPost, setFetchPost, user } = useContext(AppContext)
  const router = useParams()
  const [state, setState] = useState({
    posts: [],
    page: 0,
    limit: 50,
    showComment: "",
    videoPlaying: "",
    linkVisible: false,
    allPlayers: [],
    allUsers: [],
    linkedPlayers: [],
    statsVisible: false,
    playerId: "",
    showAllLinkedPlayers: false,
    allChannels: [],
    allUsers: []
  })

  const {
    posts,
    page,
    limit,
    showComment,
    videoPlaying,
    linkVisible,
    allPlayers,
    linkedPlayers,
    statsVisible,
    playerId,
    showAllLinkedPlayers,
    allChannels,
    allUsers
  } = state
  const token = localStorage.getItem("token")
  const [getUserLoader, setGetUserLoader] = useState(false)
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    getData(true)
    if (user?.role === "Player") {
      getAllPlayers()
      getLinkedPlayers()
    } else if (user?.role === "Parent") {
      getAllPlayers()
      getLinkedPlayers()
    }
    if (fetchPost) {
      getData(true)
      setFetchPost(false)
    }
  }, [fetchPost, linkVisible])

  useEffect(() => {
    if (actionType === "accept-link") {
      acceptLinkAction(Number(linkId))
    } else if (actionType === "decline-link") {
      declineLinkAction(Number(linkId))
    }
  }, [actionType])

  const acceptLinkAction = async linkId => {
    try {
      handleChange("loading", true)
      const payload = {
        link_id: linkId
      }
      const res = await acceptLink(payload, token)
      handleChange("loading", false)
      enqueueSnackbar(`Parent linking successful`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const declineLinkAction = async linkId => {
    try {
      handleChange("loading", true)
      const payload = {
        link_id: linkId
      }
      const res = await declineLink(payload, token)
      handleChange("loading", false)
      enqueueSnackbar(`Parent linking denied`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const getData = (resetPage, paged) => {
    const payload = router.id
    _getAllPostById(payload)
  }
  useEffect(() => {
    _getChannels()
    _getAllSharedUsers()
  }, [router.id])
  const _getAllPostById = async postID => {
    try {
      // handleChange("posts", [])
      handleChange("loading", true)
      const id = postID
      const token = localStorage.getItem("token")
      const res = await getPostById(id, token)
      handleChange("posts", res?.data)
      // handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }
  const _getComments = async (post, index) => {
    try {
      handleChange("loading", true)
      const payload = `?post__id=${post}`
      const token = localStorage.getItem("token")
      const res = await getComments(payload, token)
      handleChange(`comment${index}list`, res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }

  const clickNext = newPage => {
    getData(false, newPage)
    handleChange("page", newPage)
  }
  const handleCloseLink = () => {
    handleChange("linkVisible", false)
  }
  const handleOpenLink = type => {
    handleChange("linkVisible", true)
  }

  const getAllPlayers = async () => {
    try {
      handleChange("loading", true)
      const res = await searchUserProfile(
        "?limit=1000&offset=20&role=Player",
        token
      )
      handleChange("allPlayers", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const getLinkedPlayers = async () => {
    try {
      handleChange("loading", true)
      const res = await searchUserProfileOld("linked_relationships/", token)
      handleChange("linkedPlayers", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleClose = getTeam => {
    handleChange("statsVisible", false)
    handleChange("playerId", "")
    if (getTeam) {
      // getData(0)
    }
  }
  const _getAllSharedUsers = async () => {
    setGetUserLoader(true)
    try {
      const res = await getUsers("?limit=20&offset=20/", token)
      if (res) {
        handleChange("allUsers", res?.data?.results)
        setGetUserLoader(false)
      }
    } catch (error) {
      setGetUserLoader(false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getChannels = async () => {
    try {
      handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const [loading, setLoading] = useState(false)
  return (
    <div className="bg-white">
      <HomeHeader dashboard />
      <Element name="Element1" className="element">
        <section className="container">
          <div className="container d-flex  justify-content-center align-items-center">
            <Grid container justifyContent={"center"}>
              <Grid container justifyContent={"center"}>
                <div className="width100">
                  <p className={"JOINTHE font-36"}>
                    <span className="greyColor">
                      Welcome, {user?.name || "Guest"}
                    </span>
                  </p>
                  <Grid container spacing={3} className="mt-0">
                    <Grid item xs={12} md={8}>
                      <div className="greenBox">
                        <div className="text-white font-bold">
                          Are you on a team?
                        </div>
                        <div className="text-white">
                          Anyone can start and connect with a new team! The
                          Sports Portal is great for any sports group and
                          organization
                        </div>
                      </div>
                      <Divider className="mt-4" />

                      {posts && Object.keys(posts).length !== 0 ? (
                        <Post
                          state={state}
                          getData={getData}
                          showComment={showComment}
                          handleChange={handleChange}
                          post={posts}
                          videoPlaying={videoPlaying}
                          allPlayers={allPlayers}
                          allChannels={allChannels}
                          allUsers={allUsers}
                          getUserLoader={getUserLoader}
                        />
                      ) : (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "70%",
                            left: "40%",
                            transform: "translate(-50%, -50%)"
                          }}
                        >
                          {loading ? (
                            "Loading"
                          ) : (
                            <img
                              src={NotFoundPage}
                              style={{ marginTop: "10%", paddingBottom: 50 }}
                            />
                          )}
                        </Box>
                      )}
                      {/* {posts && Object.keys(posts).length !== 0 ? (
                        <Post
                          state={state}
                          getData={getData}
                          showComment={showComment}
                          handleChange={handleChange}
                          post={posts}
                          videoPlaying={videoPlaying}
                          allPlayers={allPlayers}
                          allChannels={allChannels}
                          allUsers={allUsers}
                          getUserLoader={getUserLoader}
                        />
                      ) : (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "70%",
                            left: "40%",
                            transform: "translate(-50%, -50%)"
                          }}
                        >
                          Loading
                        </Box>
                      )} */}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {user?.role === "Player" && (
                        <>
                          <div className="lineargreenBox">
                            <Grid
                              container
                              className="mt-3"
                              justifyContent={"space-between"}
                            >
                              <div className="text-center">
                                <div className="text-white">Followers</div>
                                <div className="text-white font-bold font-20">
                                  3,00
                                </div>
                              </div>
                              <img
                                src={user?.profile?.picture || AvatarIcon}
                                className={"c-pointer userprofileDiv"}
                              />
                              <div className="text-center">
                                <div className="text-white">Following</div>
                                <div className="text-white font-bold font-20">
                                  300
                                </div>
                              </div>
                            </Grid>
                          </div>
                          <div className="lightGreyBox">
                            <Grid container justifyContent={"center"}>
                              <AppButton
                                width={130}
                                className={"mr-2"}
                                height={30}
                                borderRadius={8}
                                title={
                                  <div>
                                    <FacebookIcon /> @Herry
                                  </div>
                                }
                                borderColor={"#D9D9D9"}
                              />
                              <AppButton
                                width={130}
                                height={30}
                                borderRadius={8}
                                title={
                                  <div>
                                    <InstaIcon /> @harrkane
                                  </div>
                                }
                                borderColor={"#D9D9D9"}
                              />
                            </Grid>
                            <Grid container justifyContent={"center"}>
                              <AppButton
                                className={"mt-3"}
                                width={130}
                                height={30}
                                borderRadius={12}
                                title={
                                  <div className="text-white font-300">
                                    <SettingWhiteIcon /> Settings
                                  </div>
                                }
                                fontWeight={"light"}
                                backgroundColor={"#34C27B"}
                              />
                            </Grid>
                          </div>
                        </>
                      )}
                      {user?.role === "Organization" && (
                        <div className="memberGreyBox">
                          <div className="text-black font-bold font-20">
                            {user?.role === "Parent" ? "Members" : "Up Coming"}
                          </div>
                          {[0, 0, 0].map((member, index) => (
                            <div className="memberBox" key={index}>
                              <div className="d-flex">
                                <img
                                  src={user?.profile?.picture || AvatarIcon}
                                  className={"c-pointer memberprofile"}
                                />
                                <div className="">
                                  <div className="font-bold text-black font-14">
                                    {user?.role === "Parent"
                                      ? member?.name || "Guest"
                                      : "Try Outs (6:00 PM - 8:00 PM)"}
                                  </div>
                                  <div className="lightgreyColor font-12">
                                    {user?.role === "Parent"
                                      ? member?.role || "Parent"
                                      : "Michigan Stadium"}
                                    {user?.role !== "Parent" && (
                                      <span className="ml-2 text-black">
                                        Test Game
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {user?.role !== "Parent" && (
                                  <span className="ml-2 mt-2 cursor text-black">
                                    View
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {user?.role === "Parent" && (
                        <div
                          className="memberGreyBox"
                          style={{ padding: "20px 15px" }}
                        >
                          <div
                            className="flex-between"
                            style={{ marginBottom: "1.5rem" }}
                          >
                            <div className="text-black font-bold font-20">
                              {user?.role === "Parent"
                                ? "Linked Players"
                                : "Up Coming"}
                            </div>
                            {!showAllLinkedPlayers ? (
                              <div
                                className="text_blue_link font-14 cursor"
                                onClick={() =>
                                  handleChange("showAllLinkedPlayers", true)
                                }
                              >
                                View All
                              </div>
                            ) : (
                              <div
                                className="text_blue_link font-14 cursor"
                                onClick={() =>
                                  handleChange("showAllLinkedPlayers", false)
                                }
                              >
                                View Less
                              </div>
                            )}
                          </div>
                          {showAllLinkedPlayers
                            ? linkedPlayers?.map((member, index) => (
                              <div className="memberBox" key={index}>
                                <div
                                  className="flex-between"
                                  style={{ width: "100%" }}
                                >
                                  <div className="d-flex">
                                    <img
                                      src={
                                        user?.profile?.picture || AvatarIcon
                                      }
                                      className={"c-pointer memberprofile"}
                                    />
                                    <div>
                                      <div
                                        className="font-bold text-black font-14 d-flex"
                                        style={{ gap: 3 }}
                                      >
                                        {member?.player?.user?.name}
                                        {!member?.is_confirmed ? (
                                          <div className="request_pending font-normal font-12 text-center px-1">
                                            Request Pending
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="lightgreyColor font-12">
                                        {
                                          member?.player
                                            ?.committed_to_organization
                                            ?.organization_name
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleChange("statsVisible", true)
                                      handleChange(
                                        "playerId",
                                        member?.player?.user?.id
                                      )
                                    }}
                                  >
                                    {user?.role === "Parent" && (
                                      <div
                                        className={`text_blue_link font-14 cursor  ${!member.is_confirmed &&
                                          "disabled-link"
                                          }`}
                                      >
                                        Add Stats
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                            : linkedPlayers
                              ?.slice(0, 4)
                              .map((member, index) => (
                                <div className="memberBox" key={index}>
                                  <div
                                    className="flex-between"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="d-flex">
                                      <img
                                        src={
                                          user?.profile?.picture || AvatarIcon
                                        }
                                        className={"c-pointer memberprofile"}
                                      />
                                      <div>
                                        <div
                                          className="font-bold text-black font-14 d-flex"
                                          style={{ gap: 3 }}
                                        >
                                          {member?.player?.user?.name}
                                          {!member?.is_confirmed ? (
                                            <div className="request_pending font-normal font-12 text-center px-1">
                                              Request Pending
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="lightgreyColor font-12">
                                          {
                                            member?.player
                                              ?.committed_to_organization
                                              ?.organization_name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      onClick={() => {
                                        handleChange("statsVisible", true)
                                        handleChange(
                                          "playerId",
                                          member?.player?.user?.id
                                        )
                                      }}
                                    >
                                      {user?.role === "Parent" && (
                                        <div
                                          className={`text_blue_link font-14 cursor  ${!member.is_confirmed &&
                                            "disabled-link"
                                            }`}
                                        >
                                          Add Stat
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          <div className="member_dotted_Box">
                            <div
                              className="flex-between font-16 font-medium cursor"
                              style={{ justifyContent: "flex-start", gap: 10 }}
                              onClick={() => handleOpenLink("link")}
                            >
                              <div className="add_bg">
                                <AddIcon fontSize="16" />
                              </div>
                              <div>Link New Player</div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="pinkBox">
                        <Grid className="mt-3">
                          <div className="">
                            <div className="adButton">
                              <div className="text-white">ad</div>
                            </div>
                            <div className="text-white font-20 mb-1">
                              Another Ad banner
                            </div>
                            <div className="text-white font-12">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis
                            </div>
                            <Grid
                              container
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              className="mt-2"
                            >
                              <div className="LearnMore">Learn More</div>
                              <div className="removeText">Remove ads</div>
                            </Grid>
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <LinkPlayer
          visible={linkVisible}
          handleClose={handleCloseLink}
          data={allPlayers}
        />
        <AddStats
          visible={statsVisible}
          playerId={playerId}
          handleClose={handleClose}
        />
      </Element>
    </div>
  )
}
