// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useState } from "react"
import { HomeHeader } from "../../components"
import {
    Divider,
    Grid, IconButton,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { COLORS } from "../../constants"
import { getPlayerToFav, markAllReadNotifications } from "../../api/auth"
import { getPayload } from "../../utils/formattedPayload"
import moment from "moment";
import AvatarIcon from "../../assets/images/avatar.png"

export default function Notifications({ }) {
    const navigate = useNavigate()
    const { notifications, _getNotifications } =
        useContext(AppContext)

    const [state, setState] = useState({
        loading: false
    })

    useEffect(() => {
        _getNotifications()
        _markAllReadNotifications()
    }, [])

    const _markAllReadNotifications = async () => {
        try {
            const token = localStorage.getItem("token")
            await markAllReadNotifications(token)
        } catch (error) {
            const errorText = Object.values(error?.response?.data)
            alert(`Error: ${errorText[0]}`)
        }
    }

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    const handleUserClick = user => {
        const role = user?.role
        const route =
            role === "Player"
                ? `/player-profile/${user?.id}`
                : role === "Organization"
                    ? `/organization-profile/${user?.id}`
                    : role === "Coach"
                        ? `/coach-profile/${user?.id}`
                        : `/parent-profile/${user?.id}`
        navigate(route)
    }

    const clickNotifcation = (item) => {
        if (item?.obj_type === "user") {
            handleUserClick(item?.sending_user)
        }
        if (item?.obj_type === "post") {
            navigate(`/post/${item?.obj_id}`)
        }
    }

    const printName = (user) => {
        const isPlayer = user?.role === "Player"
        const isParent = user?.role === "Parent"
        const isCoach = user?.role === "Coach"
        const isOrganization = user?.role === "Organization"
        const ProfileDP = isPlayer
            ? user?.player?.picture
            : isParent
                ? user?.parent?.picture
                : isOrganization
                    ? user?.organization?.logo
                    : isCoach
                        ? user?.coach?.picture
                        : user?.player?.picture || AvatarIcon
        const ProfileName = isOrganization
            ? user?.organization?.organization_name
            : user?.name || "Guest"

        return { ProfileName, ProfileDP }
    }

    return (
        <div className="">
            <HomeHeader dashboard />
            <section className="container pb-3 mb-0 bg-transparent divCenter">
                <Grid container spacing={3} className="width80">
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={3} alignItems={"center"}>
                            <Grid item>
                                <IconButton
                                    aria-label="delete"
                                    size=""
                                    sx={{
                                        bgcolor: COLORS.white,
                                        borderRadius: 2,
                                        color: "#222"
                                    }}
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBackIcon color="#222" />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <p className="font-bold font-18">
                                    Notifications
                                </p>
                            </Grid>
                        </Grid>

                        <Divider className="mt-3" />
                        {
                            Array.isArray(notifications) && notifications?.length === 0 &&
                            <div className="noNotification">No Notification</div>
                        }
                        {
                            Array.isArray(notifications) && notifications?.map((item, index) => (
                                <div onClick={() => clickNotifcation(item)} className="notificationItem" key={index}>
                                    <img
                                        src={printName(item?.sending_user)?.ProfileDP}
                                        className={"c-pointer userprofile mr-4"}
                                    />
                                    <div className="row">
                                        <div>
                                            {item?.context}
                                        </div>
                                        <div className="d-flex ml-3">
                                            <span className="graydot" />
                                            <span className="text-gray">
                                                {moment(item?.timestamp).fromNow()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div style={{ height: 20 }} />
                    </Grid>
                </Grid>
            </section>
        </div>
    )
}
