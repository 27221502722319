import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AppButton, AppInput, Loader } from "..";
import { COLORS, MAP_API_KEY } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  TablePagination,
  TextField,
  Autocomplete as TextAutocomplete,
} from "@mui/material";
import AutoComplete from "react-google-autocomplete";
import {
  createGameSchedules,
  deleteGameSchedules,
  getTargetTeamCompetition,
  getTeams,
  updateGameSchedules,
} from "../../api/auth";
import { useEffect } from "react";
import moment from "moment";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete.svg";
import { ReactComponent as PencilBlue } from "../../assets/svg/pencilBlue.svg";
import { useSnackbar } from "notistack";
import { getError } from "../../utils/getError";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFAFA",
    color: "#000000D9",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  minWidth: "700px",
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function ScheduleTable({
  isAddNew,
  gameSchedule,
  loading,
  getData,
  rowsPage,
  page,
  limit,
  handleChangePage,
  handleChangeMain,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPage || 5);
  const handleEditTeam = (id) => {
    navigate(`/teams/edit/${id}`);
  };
  const { enqueueSnackbar } = useSnackbar();

  const token = localStorage.getItem("token");

  const [state, setState] = React.useState({
    loading: false,
    isEditID: false,
    practiceScheduleVisible: false,
    date: "",
    tournament_circuit: "",
    arrival_time: new Date(),
    game_time: new Date(),
    location: "",
    latitude: "",
    longitude: "",
    notes: "",
    targetTeamCompetition: [],
    teams: [],
    opponent: "",
  });

  const {
    isEditID,
    latitude,
    longitude,
    date,
    notes,
    opponent,
    opponent_team_custom,
    teams,
    targetTeamCompetition,
    arrival_time,
    game_time,
    location,
    tournament_circuit,
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    _getTeams();
  }, [isAddNew]);

  const _getTeams = async () => {
    try {
      const res = await getTeams(token);
      handleChange("teams", res?.data);
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const _getTargetTeamCompetition = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await getTargetTeamCompetition(token);
      handleChange("targetTeamCompetition", res?.data?.results);
    } catch (error) {
    }
  };

  const _createGameSchedules = async (scheduleID) => {
    try {
      handleChange("loading", true);
      const token = localStorage.getItem("token");
      const payload = {
        date,
        notes,
        opponent,
        arrival_time: moment(new Date(arrival_time)).format("HH:mm"),
        game_time: moment(new Date(game_time)).format("HH:mm"),
        location,
        latitude,
        longitude,
        tournament_circuit,
        team: id,
      };
      if (!tournament_circuit) {
        delete payload?.tournament_circuit
      }
      if (!opponent) {
        payload["opponent_team_custom"] = opponent_team_custom;
        delete payload.opponent;
      }
      if (!notes) {
        delete payload.notes;
      }
      if (scheduleID) {
        await updateGameSchedules(scheduleID, payload, token);
      } else {
        await createGameSchedules(payload, token);
      }
      handleChangePage(null, 0);
      handleChange("date", "");
      handleChange("arrival_time", new Date());
      handleChange("game_time", new Date());
      handleChange("location", "");
      handleChange("opponent_team_custom", "");
      handleChange("tournament_circuit", "");
      handleChange("opponent", "");
      handleChange("notes", "");
      handleChangeMain("isAddNew", false);
      handleChange("isEditID", "");
      handleChange("loading", false);
    } catch (error) {
      handleChange("loading", false);
    }
  };
  const handleReset = () => {
    handleChange("date", "");
    handleChange("arrival_time", new Date());
    handleChange("game_time", new Date());
    handleChange("location", "");
    handleChange("latitude", "");
    handleChange("longitude", "");
    handleChange("opponent_team_custom", "");
    handleChange("tournament_circuit", "");
    handleChange("opponent", "");
    handleChange("notes", "");
    handleChangeMain("isAddNew", false);
    handleChange("isEditID", "");
  };
  const _deleteGameSchedules = async (id) => {
    try {
      handleChange("loading", true);
      const token = localStorage.getItem("token");
      await deleteGameSchedules(id, token);
      handleChangePage(null, page);
      handleChange("loading", false);
    } catch (error) {
      handleChange("loading", false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0)
  };

  return (
    <TableContainer>
      {!isEditID && loading ? (
        <div className="divCenter fullWidthWeb">
          <Loader />
        </div>
      ) : (
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="left">Arrival Time</StyledTableCell>
              <StyledTableCell align="left">Game Time</StyledTableCell>
              <StyledTableCell align="left">Location</StyledTableCell>
              <StyledTableCell align="left">Opponent</StyledTableCell>
              <StyledTableCell align="left" width={120}>
                Tournament
              </StyledTableCell>
              <StyledTableCell align="left">Notes(Optional)</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isAddNew && (
              <StyledTableRow>
                <StyledTableCell align="left" width={120}>
                  <AppInput
                    type={"date"}
                    width={150}
                    min={moment().format("YYYY-MM-DD")}
                    height={40}
                    onChange={handleChange}
                    value={date}
                    name={"date"}
                  />
                </StyledTableCell>
                <StyledTableCell align="left" width={180}>
                  <AppInput
                    type={"time"}
                    marginTop={25}
                    schedule
                    onChange={handleChange}
                    value={arrival_time}
                    name={"arrival_time"}
                    muiTime
                  />
                </StyledTableCell>
                <StyledTableCell align="left" width={180}>
                  <AppInput
                    type={"time"}
                    marginTop={25}
                    schedule
                    onChange={handleChange}
                    value={game_time}
                    name={"game_time"}
                    muiTime
                  />
                </StyledTableCell>
                <StyledTableCell align="left" width={200}>
                  <AutoComplete
                    apiKey={MAP_API_KEY}
                    className="locationSearch"
                    options={{ types: ["geocode", "establishment"] }}
                    style={{
                      height: 40,
                      marginTop: 10,
                      backgroundColor: "#fff",
                    }}
                    onChange={(e) => handleChange("location", e?.target?.value)}
                    defaultValue={location}
                    onPlaceSelected={(place) => {
                      handleChange("location", place?.formatted_address);
                      handleChange("latitude", place?.geometry.location?.lat());
                      handleChange(
                        "longitude",
                        place?.geometry.location?.lng()
                      );
                    }}
                    placeholder=""
                  />
                </StyledTableCell>
                <StyledTableCell align="left" width={200}>
                  <TextAutocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    value={opponent_team_custom}
                    onChange={(event, newValue) => {
                      handleChange("opponent_team_custom", newValue);
                      handleChange(
                        "opponent",
                        teams[event?.target?.dataset?.optionIndex]?.id
                      );
                    }}
                    inputValue={opponent_team_custom}
                    onInputChange={(event, newInputValue) => {
                      handleChange("opponent_team_custom", newInputValue);
                      handleChange("opponent", "");
                    }}
                    options={teams?.map((option) => option.team_name)}
                    label={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          marginTop: 1,
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          "& .MuiOutlinedInput-root  .MuiOutlinedInput-notchedOutline":
                          {
                            borderRadius: 2,
                          },
                          "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#D9D9D9",
                            borderWidth: 1,
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#D9D9D9",
                          },
                        }}
                        label=""
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                  {/* <AppInput height={40} placeholder={"Opponent"} select selectOptions={targetTeamCompetitionOptions} value={opponent} name={'opponent'} onChange={handleChange} /> */}
                </StyledTableCell>
                <StyledTableCell align="left" width={80}>
                  <AppInput
                    onChange={handleChange}
                    height={40}
                    value={tournament_circuit}
                    name={"tournament_circuit"}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <AppInput
                    onChange={handleChange}
                    height={40}
                    value={notes}
                    multiline
                    marginTop={1}
                    textAreaWidth={'90%'}
                    textAreaMarginTop={7}
                    name={"notes"}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div className="d-flex" style={{ marginLeft: -10 }}>
                    <AppButton
                      disabled={
                        !date ||
                        !arrival_time ||
                        !game_time ||
                        !location
                        || !opponent_team_custom
                        // !tournament_circuit
                      }
                      width={50}
                      height={30}
                      className={"min-width-remove"}
                      title={"Add"}
                      color={COLORS.primary}
                      backgroundColor={"transparent"}
                      fontWeight={400}
                      onClick={() => _createGameSchedules("")}
                    />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
            {gameSchedule?.results?.map((team) =>
              isEditID === team?.id ? (
                <StyledTableRow>
                  <StyledTableCell align="left" width={120}>
                    <AppInput
                      type={"date"}
                      mainWidth={140}
                      min={moment().format("YYYY-MM-DD")}
                      height={40}
                      onChange={handleChange}
                      value={date}
                      name={"date"}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left" width={180}>
                    <AppInput
                      type={"time"}
                      marginTop={25}
                      schedule
                      onChange={handleChange}
                      value={arrival_time}
                      name={"arrival_time"}
                      muiTime
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left" width={180}>
                    <AppInput
                      type={"time"}
                      marginTop={25}
                      schedule
                      onChange={handleChange}
                      value={game_time}
                      name={"game_time"}
                      muiTime
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left" width={200}>
                    <AutoComplete
                      apiKey={MAP_API_KEY}
                      className="locationSearch"
                      options={{ types: ["geocode", "establishment"] }}
                      style={{
                        height: 40,
                        marginTop: 10,
                        backgroundColor: "#fff",
                      }}
                      onChange={(e) =>
                        handleChange("location", e?.target?.value)
                      }
                      defaultValue={location}
                      onPlaceSelected={(place) => {
                        handleChange("location", place?.formatted_address);
                        handleChange(
                          "latitude",
                          place?.geometry.location?.lat()
                        );
                        handleChange(
                          "longitude",
                          place?.geometry.location?.lng()
                        );
                      }}
                      placeholder=""
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left" width={200}>
                    <TextAutocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      value={opponent_team_custom}
                      onChange={(event, newValue) => {
                        handleChange("opponent_team_custom", newValue);
                        handleChange(
                          "opponent",
                          teams[event?.target?.dataset?.optionIndex]?.id
                        );
                      }}
                      inputValue={opponent_team_custom}
                      onInputChange={(event, newInputValue) => {
                        handleChange("opponent_team_custom", newInputValue);
                        handleChange("opponent", "");
                      }}
                      options={teams?.map((option) => option.team_name)}
                      label={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            marginTop: 1,
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            "& .MuiOutlinedInput-root  .MuiOutlinedInput-notchedOutline":
                            {
                              borderRadius: 2,
                            },
                            "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#D9D9D9",
                              borderWidth: 1,
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#D9D9D9",
                            },
                          }}
                          label=""
                          InputLabelProps={{ shrink: false }}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left" width={80}>
                    <AppInput
                      onChange={handleChange}
                      height={40}
                      value={tournament_circuit}
                      name={"tournament_circuit"}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <AppInput
                      onChange={handleChange}
                      height={40}
                      value={notes}
                      name={"notes"}
                      multiline
                      marginTop={1}
                      textAreaWidth={'90%'}
                      textAreaMarginTop={7}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className="d-flex" style={{ marginLeft: -10 }}>
                      <AppButton
                        disabled={
                          !date ||
                          !arrival_time ||
                          !game_time ||
                          !location
                          || !opponent_team_custom
                          // !tournament_circuit
                        }
                        loading={loading}
                        width={50}
                        height={30}
                        className={"min-width-remove"}
                        title={"Edit"}
                        color={COLORS.primary}
                        backgroundColor={"transparent"}
                        fontWeight={400}
                        onClick={() => _createGameSchedules(team?.id)}
                      />
                      <AppButton
                        width={50}
                        height={30}
                        className={"min-width-remove"}
                        title={"Cancel"}
                        color={COLORS.primary}
                        backgroundColor={"transparent"}
                        fontWeight={400}
                        onClick={handleReset}
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow key={team?.team_name}>
                  <StyledTableCell align="left" width={120}>
                    {moment(team?.date).format("LL")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {team?.arrival_time || "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {team?.game_time || "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {team?.location || "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {team?.opponent?.team_name || "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left" width={80}>
                    {team?.tournament_circuit || "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {team?.notes || "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className="d-flex" style={{ marginLeft: -10 }}>
                      <AppButton
                        height={30}
                        width={50}
                        className={"min-width-remove"}
                        title={
                          <PencilBlue
                            width={30}
                            height={30}
                            style={{ width: 30, height: 30 }}
                          />
                        }
                        color={COLORS.primary}
                        backgroundColor={"transparent"}
                        onClick={() => {
                          handleChange("date", team?.date);
                          handleChange(
                            "arrival_time",
                            moment(
                              moment().format("YYYY-MM-DD ") +
                              team?.arrival_time
                            ).format()
                          );
                          handleChange(
                            "game_time",
                            moment(
                              moment().format("YYYY-MM-DD ") + team?.game_time
                            ).format()
                          );
                          handleChange("location", team?.location);
                          handleChange(
                            "tournament_circuit",
                            team?.tournament_circuit
                          );
                          handleChange("opponent", team?.opponent?.id);
                          handleChange(
                            "opponent_team_custom",
                            team?.opponent?.team_name ||
                            team?.opponent_team_custom
                          );
                          handleChange("notes", team?.notes);
                          handleChange("isEditID", team?.id);
                          handleChangeMain("isAddNew", false);
                        }}
                      />
                      <AppButton
                        height={30}
                        className={"min-width-remove"}
                        title={<DeleteIcon />}
                        color={COLORS.primary}
                        backgroundColor={"transparent"}
                        fontWeight={400}
                        onClick={() => _deleteGameSchedules(team?.id)}
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )
            )}
          </TableBody>
        </Table>
      )}
      {gameSchedule?.count > 0 && (
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={gameSchedule?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
}
