// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeHeader, GoogleMapComp, Label } from "../../components"
import { Grid } from "@mui/material"
import { ReactComponent as BackButton } from "../../assets/svg/BackButton.svg"
import { ReactComponent as MapButton } from "../../assets/svg/MapButton.svg"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  updateProfile
} from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import { useSnackbar } from "notistack"
import { COLORS, MAP_API_KEY } from "../../constants"
import AutoComplete from "react-google-autocomplete"

export default function LocationAccess({ }) {
  const navigate = useNavigate()
  const [getQuery] = useSearchParams()
  const isMapShow = getQuery.get('map')
  const { enqueueSnackbar } = useSnackbar()
  const { user, isCoach, isPlayer,
    isParent,
    isOrganization, _getProfile } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const [state, setState] = useState({
    address: "",
    city: "",
    country: "",
    zip: "",
    selectedState: "",
    latitude: "",
    longitude: "",
    loading: false,
    isMap: false,
  })

  useEffect(() => {
    if (isMapShow) {
      handleChange("isMap", true)
    }
  }, [isMapShow])

  useEffect(() => {
    if (user) {
      handleChange("city", user?.city)
      handleChange("country", user?.country)
      handleChange("zip_code", user?.zip_code)
      handleChange("address", user?.street_address)
      handleChange("latitude", user?.latitude)
      handleChange("longitude", user?.longitude)
      handleChange("selectedState", user?.state)
    }
  }, [user])

  const {
    address,
    city,
    selectedState,
    loading,
    zip_code,
    country,
    isMap,
    latitude,
    longitude
  } = state

  const handleChange = (key, value) => {

    if ((key !== "address" && key !== 'latitude' && key !== 'longitude' && !validateAddress(value))) {
      debugger
      return
    }
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  function validateAddress(TCode) {

    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(TCode)) {
      alert('Input is not alphanumeric');
      return false;
    }

    return true;
  }

  const handleSignup = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        street_address: address,
        country,
        zip_code,
        city,
        state: selectedState,
        latitude,
        longitude
      }
      await updateProfile(payload, user?.id, token)
      _getProfile(user?.id)
      handleChange("loading", false)
      if (isOrganization) {
        navigate(`/organization-profile/${user?.id}/add`)
      } else if (isParent) {
        navigate(`/parent-profile/${user?.id}/add`)
      } else if (isPlayer) {
        navigate("/player-profile/add")
      } else if (isCoach) {
        navigate(`/coach-profile/${user?.id}/add`)
      } else {
        navigate("/dashboard")
      }
      enqueueSnackbar(`Location has been updated`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const handleSearch = async (place) => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 &&
        res1?.results[0]?.formatted_address
      var address_components =
        res1?.results[0]?.address_components
      let dState = ''
      let country = ''
      let city = ''
      let postal_code = ''
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === 'administrative_area_level_1') {
            dState = addrComp[i]?.long_name
          } else if (typ === 'locality') {
            city = addrComp[i]?.long_name
          } else if (typ === 'country') {
            country = addrComp[i]?.long_name
          } else if (typ === 'postal_code') {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange('latitude', place?.geometry.location?.lat())
      handleChange('longitude', place?.geometry.location?.lng())
      handleChange('address', address)
      handleChange('city', city)
      handleChange('country', country)
      handleChange('selectedState', dState)
      handleChange('zip_code', postal_code)
    } catch (error) {
      alert(error?.message)
    }
  }

  const handleSearchCurrent = async (position) => {
    try {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 &&
        res1?.results[0]?.formatted_address
      var address_components =
        res1?.results[0]?.address_components
      let dState = ''
      let country = ''
      let city = ''
      let postal_code = ''
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === 'administrative_area_level_1') {
            dState = addrComp[i]?.long_name
          } else if (typ === 'locality') {
            city = addrComp[i]?.long_name
          } else if (typ === 'country') {
            country = addrComp[i]?.long_name
          } else if (typ === 'postal_code') {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange('latitude', lat)
      handleChange('longitude', lng)
      handleChange('address', address)
      handleChange('city', city)
      handleChange('country', country)
      handleChange('selectedState', dState)
      handleChange('zip_code', postal_code)
    } catch (error) {
      alert(error?.message)
    }
  }

  function error() {
  }

  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSearchCurrent, error);
    } else {
    }
  }

  return (
    <div>
      <HomeHeader />
      <section>
        <div className="container loginContainer">
          {
            isMap ?
              <GoogleMapComp isMapShow={isMapShow} handleChangeMain={handleChange} />
              :
              <Grid container justifyContent={"center"}>

                <Grid item xs={10} sm={10} md={6} lg={4} className="">
                  <Grid container justifyContent={'space-between'} className="mb-4">
                    <BackButton onClick={() => console.log('')} className="cursor" />
                    <MapButton onClick={() => handleLocationClick()} className="cursor" />
                  </Grid>
                  <div className="text-left">
                    <div className="font-24 greyColor">
                      Set your location
                    </div>
                  </div>
                  <Label text={"Street Address"} />
                  <AutoComplete
                    apiKey={MAP_API_KEY}
                    className="locationSearch"
                    options={{ types: ["geocode", "establishment"] }}
                    style={{ height: 55, backgroundColor: "#fff" }}
                    defaultValue={address}
                    onChange={(e) => handleChange("address", e?.target?.value)}
                    onPlaceSelected={place => {
                      handleSearch(place)
                    }}
                    placeholder=""
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <AppInput
                        label={"City"}
                        value={city}
                        name={"city"}
                        onChange={handleChange}
                        className="mb-3 mt-3"
                        placeholder={""}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppInput
                        label={"State"}
                        value={selectedState}
                        name={"selectedState"}
                        onChange={handleChange}
                        className="mb-3 mt-3"
                        placeholder={""}
                      />
                    </Grid>
                  </Grid>
                  <AppInput
                    className="mb-4 mt-3"
                    value={zip_code}
                    name={"zip_code"}
                    onChange={handleChange}
                    label={"Zip"}
                    placeholder={""}
                  />
                  <AppInput
                    className="mb-4 mt-3"
                    value={country}
                    name={"country"}
                    onChange={handleChange}
                    label={"Country"}
                    placeholder={""}
                  />
                  <AppButton
                    title={"Save"}
                    className={'mb-5'}
                    inputWidthFull
                    onClick={handleSignup}
                    loading={loading}
                    disabled={!address || !city || !selectedState || !country || !zip_code}
                    backgroundColor={COLORS.primary}
                    color={"#fff"}
                  />
                </Grid>
              </Grid>}
        </div>
      </section>
    </div>
  )
}
