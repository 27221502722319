import React from 'react'
import AppInput from '../AppInput'
import AppButton from '../AppButton'
import { COLORS } from '../../constants'
import { Grid } from '@mui/material'

function ContactUs() {
  return (
    <div class='contact-us'>
      <div class='container width70'>
        <div className='contact-us-head'>Contact Us</div>
        <div>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <AppInput placeholder={''} label={'First Name'} />
            </Grid>
            <Grid item md={6}>
              <AppInput placeholder={''} label={'Last Name'} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className='mt-4 mb-5'>
            <Grid item md={6}>
              <AppInput placeholder={''} label={'Email'} />
            </Grid>
            <Grid item md={6}>
              <AppInput placeholder={''} label={'Your Message'} />
            </Grid>
          </Grid>
          <AppButton title={'Send Message'} backgroundColor={COLORS.primary} color={COLORS.white} width={150} className={'pl-2 pr-2 mt-3 mb-5'} />
        </div>
      </div>
    </div>
  )
}

export default ContactUs
