import React, { useContext, useEffect, useState } from "react"
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput"
import { styled } from "@mui/system"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
  colors
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  addVolleyballStats,
  createOrganizationTeamStat,
  getOrganizationTeam,
  getUserProfile,
  updatePlayerTeamStat,
  updateVolleyballStats
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import QuantityInput from "../AppInput/NumberInput"

function VolleyBallStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  playerStatOption,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_game: "",
    team_played: "",
    team_played_custom: "",
    result: "",
    points_scored: "",
    total_attempts: 0,
    assists: 0,
    sets_played: 0,
    kills: 0,
    attack_errors: 0,
    set_attempts: 0,
    digs: 0,
    blocks: 0,
    passing_rating: 0,
    service_aces: 0,
    total_serves: 0,
    block_solos: 0,
    service_errors: 0,
    block_assists: 0,
    good_passes: 0,
    passing_attempts: 0,
    set_errors: 0,
    reception_attempts: 0,
    passing_efficiency: 0,
    reception_errors: 0,
    loading: false,
    teams: [],
    userProfileData: null
  })
  const {
    date_of_game,
    team_played,
    team_played_custom,
    result,
    points_scored,
    total_attempts,
    assists,
    sets_played,
    kills,
    set_attempts,
    attack_errors,
    digs,
    blocks,
    passing_rating,
    service_aces,
    total_serves,
    service_errors,
    block_solos,
    block_assists,
    good_passes,
    passing_attempts,
    loading,
    teams,
    reception_errors,
    set_errors,
    passing_efficiency,
    reception_attempts,
    userProfileData
  } = state

  const points_scored_calculated =
    Number(total_serves || 0) * 2 +
    Number(service_errors || 0) * 3 +
    Number(good_passes || 0) * 1

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if (visible) {
      getData()
      _getProfileData()
    }
  }, [visible])

  const _getProfileData = async () => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("token")
      const res = await getUserProfile(user?.role === "Parent" ? playerId?.userID : user?.id, token)
      handleChange("userProfileData", res?.data)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_game", statData?.date_of_game)
      // getData(`?`)
      if (teams?.length && teams?.some((e) => e?.id == statData?.team_played)) {
        setDropDownText(teams?.find((e) => e?.id == statData?.team_played)?.team_name)
      }
      handleChange("team_played", statData?.team_played)
      handleChange("team_played_custom", statData?.team_played_custom)
      handleChange("points_scored_calculated", statData?.points_scored_calculated)
      handleChange("total_attempts", statData?.total_attempts)
      handleChange("assists", statData?.assists)
      handleChange("sets_played", statData?.sets_played)
      handleChange("kills", statData?.kills)
      handleChange("digs", statData?.digs)
      handleChange("blocks", statData?.blocks)
      handleChange("passing_rating", statData?.passing_rating)
      handleChange("service_aces", statData?.service_aces)
      handleChange("total_serves", statData?.total_serves)
      handleChange("block_solos", statData?.block_solos)
      handleChange("service_errors", statData?.service_errors)
      handleChange("block_assists", statData?.block_assists)
      handleChange("good_passes", statData?.good_passes)
      handleChange("passing_attempts", statData?.passing_attempts)
      handleChange("attack_errors", statData?.attack_errors)
      handleChange("set_attempts", statData?.set_attempts)
      handleChange("set_errors", statData?.set_errors)
      handleChange("reception_attempts", statData?.reception_attempts)
      handleChange("passing_efficiency", statData?.passing_efficiency)
      handleChange("reception_errors", statData?.reception_errors)
    } else {
      handleChange("date_of_game", "")
      handleChange("team_played", "")
      handleChange("result", "")
      handleChange("team_played_custom", "")
      handleChange("total_attempts", 0)
      handleChange("assists", 0)
      handleChange("sets_played", 0)
      handleChange("kills", 0)
      handleChange("attack_errors", 0)
      handleChange("set_attempts", 0)
      handleChange("digs", 0)
      handleChange("blocks", 0)
      handleChange("passing_rating", 0)
      handleChange("service_aces", 0)
      handleChange("points_scored", 0)
      handleChange("block_solos", 0)
      handleChange("total_serves", 0)
      handleChange("service_errors", 0)
      handleChange("block_assists", 0)
      handleChange("good_passes", 0)
      handleChange("set_errors", 0)
      handleChange("reception_attempts", 0)
      handleChange("reception_errors", 0)
      handleChange("passing_efficiency", 0)
      setDropDownText("")
      handleChange("passing_attempts", 0)
    }
  }, [statData, visible, teams])

  const getData = async (payload = '') => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(payload, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (team_played === "" && team_played_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const assistsValue = assists || 0
        const payload = {
          date_of_game,
          team_played,
          team_played_custom,
          points_scored: points_scored_calculated,
          assists: assistsValue,
          total_attempts,
          sets_played,
          kills,
          attack_errors,
          set_attempts,
          digs,
          blocks,
          passing_rating,
          service_aces,
          total_serves,
          block_solos,
          service_errors,
          block_assists,
          good_passes,
          passing_attempts,
          reception_attempts,
          set_errors,
          passing_efficiency,
          reception_errors
        }
        if (user?.role === "Parent") {
          payload.player_id = playerId?.id // Add player_id if user's role is parent
        }
        await addVolleyballStats(payload, token)
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("team_played", "")
        handleChange("result", "")
        handleChange("team_played_custom", "")
        handleChange("assists", 0)
        handleChange("total_attempts", 0)
        handleChange("sets_played", 0)
        handleChange("kills", 0)
        handleChange("attack_errors", 0)
        handleChange("set_attempts", 0)
        handleChange("digs", 0)
        handleChange("blocks", 0)
        handleChange("passing_rating", 0)
        handleChange("service_aces", 0)
        handleChange("points_scored", 0)
        handleChange("block_solos", 0)
        handleChange("total_serves", 0)
        handleChange("service_errors", 0)
        handleChange("block_assists", 0)
        handleChange("good_passes", 0)
        handleChange("set_errors", 0)
        handleChange("reception_attempts", 0)
        handleChange("passing_efficiency", 0)
        handleChange("reception_errors", 0)
        setDropDownText("")
        handleChange("passing_attempts", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (team_played === "" && team_played_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const assistsValue = assists || 0

        if (organization) {
          const payload = {
            date_of_game,
            team_played,
            result,
            organization: organizationData?.id,
            team: teamID
          }
          await createOrganizationTeamStat(payload, token)
        } else {
          const payload = {
            date_of_game,
            team_played,
            team_played_custom,
            points_scored: points_scored_calculated,
            assists: assistsValue,
            total_attempts,
            sets_played,
            kills,
            attack_errors,
            set_attempts,
            digs,
            blocks,
            passing_rating,
            service_aces,
            total_serves,
            block_solos,
            service_errors,
            block_assists,
            good_passes,
            passing_attempts,
            set_errors,
            reception_attempts,
            passing_efficiency,
            reception_errors
          }
          if (user?.role === "Parent") {
            payload.player_id = playerId?.id // Add player_id if user's role is parent
          }
          await updateVolleyballStats(statData?.id, payload, token)
        }
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("team_played", "")
        handleChange("result", "")
        handleChange("team_played_custom", "")
        handleChange("assists", 0)
        handleChange("total_attempts", 0)
        handleChange("sets_played", 0)
        handleChange("kills", 0)
        handleChange("attack_errors", 0)
        handleChange("set_attempts", 0)
        handleChange("digs", 0)
        handleChange("blocks", 0)
        handleChange("passing_rating", 0)
        handleChange("service_aces", 0)
        handleChange("points_scored", 0)
        handleChange("block_solos", 0)
        handleChange("total_serves", 0)
        handleChange("service_errors", 0)
        handleChange("block_assists", 0)
        handleChange("good_passes", 0)
        handleChange("set_errors", 0)
        handleChange("reception_attempts", 0)
        handleChange("passing_efficiency", 0)
        handleChange("reception_errors", 0)
        setDropDownText("")
        handleChange("passing_attempts", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <Grid container spacing={3}>
          {user?.role == "Parent" && (
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  label={"Adding stats for"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={playerId?.name}
                  name={"playerId"}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <AppInput
              label={"Date of game"}
              max={moment().format("YYYY-MM-DD")}
              type={"date"}
              height={40}
              required={true}
              onChange={handleChange}
              value={date_of_game}
              name={"date_of_game"}
            />
            {dateOfGameError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text={"Team Played"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={
                team_played_custom === "" ? dropDownText : team_played_custom
              }
              onChange={(event, newValue) => {
                handleChange("team_played_custom", "")
                handleChange(
                  "team_played",
                  teams[event?.target?.dataset?.optionIndex]?.id
                )
                setDropDownText(newValue)
              }}
              inputValue={
                team_played_custom === "" ? dropDownText : team_played_custom
              }
              onInputChange={(event, newInputValue) => {
                handleChange("team_played_custom", newInputValue)
                handleChange("team_played", "")
              }}
              options={teams?.map(option => option.team_name)}
              // getOptionLabel={(option) => option.team_name}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                    // Add your desired z-index value here
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
            {teamPlayedError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Attacks"}
              onChange={handleChange}
              value={total_attempts}
              name={"total_attempts"}
            />
          </Grid>
          {
            userProfileData?.player?.first_position_play?.name === "Setter" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Assists"}
                  onChange={handleChange}
                  value={assists}
                  name={"assists"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Sets Played"}
                  onChange={handleChange}
                  value={sets_played}
                  name={"sets_played"}
                />
              </Grid>
            </>
          }
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Kills"}
              onChange={handleChange}
              value={kills}
              name={"kills"}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Digs"}
              onChange={handleChange}
              value={digs}
              name={"digs"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Blocks"}
              onChange={handleChange}
              value={blocks}
              name={"blocks"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Service Aces"}
              onChange={handleChange}
              value={service_aces}
              name={"service_aces"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Passing Rating"}
              onChange={handleChange}
              value={passing_rating}
              name={"passing_rating"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Total Serves"}
              onChange={handleChange}
              value={total_serves}
              name={"total_serves"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Solos Block"}
              onChange={handleChange}
              value={block_solos}
              name={"block_solos"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Service Errors"}
              onChange={handleChange}
              value={service_errors}
              name={"service_errors"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Block Assists"}
              onChange={handleChange}
              value={block_assists}
              name={"block_assists"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Good Passes"}
              onChange={handleChange}
              value={good_passes}
              name={"good_passes"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Passing Attempts"}
              onChange={handleChange}
              value={passing_attempts}
              name={"passing_attempts"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Attack Errors"}
              onChange={handleChange}
              value={attack_errors}
              name={"attack_errors"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Set Attempts"}
              onChange={handleChange}
              value={set_attempts}
              name={"set_attempts"}
            />
          </Grid>
          {
            userProfileData?.player?.first_position_play?.name === "Defensive Specialist" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Reception Attempts"}
                  onChange={handleChange}
                  value={reception_attempts}
                  name={"reception_attempts"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Reception Errors"}
                  onChange={handleChange}
                  value={reception_errors}
                  name={"reception_errors"}
                />
              </Grid>
            </>
          }
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Set Errors"}
              onChange={handleChange}
              value={set_errors}
              name={"set_errors"}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Passing Efficiency"}
              onChange={handleChange}
              value={passing_efficiency}
              name={"passing_efficiency"}
            />
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            // disabled={disabled}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default VolleyBallStats
