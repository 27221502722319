// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeHeader } from "../../components"
import { Grid } from "@mui/material"
import eyeIcon from "../../assets/svg/eye.svg"
import eyeOff from "../../assets/svg/eyeOff.svg"
import { ReactComponent as EmailIcon } from "../../assets/svg/email.svg"
import { ReactComponent as PasswordIcon } from "../../assets/svg/password.svg"
import { ReactComponent as CrossButton } from "../../assets/svg/CrossButton.svg"
import { useNavigate } from "react-router-dom"
import {
  loginUser, updateProfile, validateOTP, verifyOTP,
} from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import { useSnackbar } from "notistack"
import { COLORS } from "../../constants"
import AuthCode from 'react-auth-code-input';
import Logo from "../../assets/svg/logo.svg"

export default function AdminLogin({ }) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { user, setUser, _getProfile } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    email: "",
    password: "",
    code: "",
    loading: false,
    showPassword: false,
    isOTP: false,
    default_auth: ''
  })

  useEffect(() => {
    if (user?.is_2fa) {
      navigate("/")
    }
    if (user && !user?.is_2fa) {
      handleChange("isOTP", true)
    }
  }, [user])

  const {
    email,
    password,
    loading,
    showPassword,
    isOTP,
    code,
    default_auth
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleLogin = async () => {
    try {

      handleChange("loading", true)
      const payload = {
        username: email,
        password
      }
      const res = await loginUser(payload)
      if (!res?.data?.default_auth) {
        navigate("/webadmin/users")
        localStorage.setItem("admintoken", res?.data?.token)
      } else {
        alert('Please use admin credentials')
      }
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }


  return (
    <div>
      <section>
        <div className="container loginContainer">
          <Grid container justifyContent={"center"}>
            <Grid item xs={10} sm={10} md={6} lg={4} className="">
              <div className="text-center">
                <img src={Logo} className="applogo mb-3" />
                <div className="font-24 greyColor">
                  Welcome Back
                </div>
              </div>
              <AppInput
                label={"Enter"}
                value={email}
                name={"email"}
                prefix={<div className="emailIcon">
                  <EmailIcon /></div>}
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Enter email"}
              />
              <AppInput
                className="mb-4 mt-3"
                label={"Password"}
                value={password}
                prefix={<div className="passwordIcon"><PasswordIcon /></div>}
                type={showPassword ? "text" : "password"}
                name={"password"}
                onChange={handleChange}
                postfix={
                  <img
                    src={!showPassword ? eyeIcon : eyeOff}
                    onClick={() =>
                      handleChange("showPassword", !showPassword)
                    }
                    width={20}
                    className={"c-pointer"}
                  />
                }
                placeholder={"********"}
              />
              <div
                className="text-left mb-5 c-pointer"
                onClick={() => navigate("/forgot-password")}
              >
                <p className="font-bold text_primary">Forgot password</p>
              </div>
              <AppButton
                title={"Log In"}
                onClick={handleLogin}
                loading={loading}
                disabled={!email || !password}
                backgroundColor={COLORS.primary}
                color={"#fff"}
              />
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  )
}
