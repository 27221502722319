import React, { useContext } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  List,
  Popover,
  IconButton,
  Drawer,
  Box,
} from "@mui/material"
import { ReactComponent as Down } from "../../assets/svg/down.svg"
import { ReactComponent as ProfileIcon } from "../../assets/svg/profile.svg"
import { ReactComponent as SettingsIcon } from "../../assets/svg/settings.svg"
import { ReactComponent as LogoutIcon } from "../../assets/svg/logout.svg"
import "rsuite/dist/rsuite.min.css"
import AppContext from "../../Context"
import AppButton from "../AppButton"
import MenuIcon from "@mui/icons-material/Menu"
import { COLORS } from "../../constants"


export default function AdminHeader({ PlayerProfile, admin }) {
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  let userData = localStorage.getItem("userData")
  const { ProfileName, ProfileDP, user, resetState, isPlayer, isOrganization, organizationData } = useContext(AppContext)
  const [state, setState] = React.useState({
    openDrawer: false,
    searchText: '',
    userList: []
  })
  const { openDrawer } = state
  userData = JSON.parse(userData)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleDrawer = open => {
    handleChange('openDrawer', open)
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const logout = () => {
    localStorage.removeItem("admintoken")
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    resetState()
    navigate("/webadmin/login")
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const currentUser = user?.is_2fa

  return (
    <div>
      <header className={`adminHeader`}>
        <div className={`container`}>
          <div className="row justify-content-end mt-2 mb-2">
            <div className="listpool text-white mr-4">
              <img
              onClick={handleClick}
                src={ProfileDP}
                className={"c-pointer userprofile"}
              />
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              classes={{ paper: "dropdown-paper" }}
              sx={{ width: 250 }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}

            >
              <div>
                <AppButton
                  width={200}
                  title={
                    <div className="dropdownItem">
                      <LogoutIcon />
                      <div className="ml-2">Log Out</div>
                    </div>
                  }
                  className={"text-left"}
                  onClick={logout}
                  backgroundColor={"#fff"}
                  color={"#000"}
                />
              </div>
            </Popover>
            <Drawer
              anchor={"left"}
              open={openDrawer}
              onClose={() => toggleDrawer(false)}
            >
              <Box
                sx={{
                  width: 250
                }}
                role="presentation"
              >
                <List>
                </List>
              </Box>
            </Drawer>
          </div>
        </div>
      </header>
    </div>
  )
}
