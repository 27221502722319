import React, { useContext, useEffect, useRef, useState } from "react"
import { AppButton, AppInput, HomeHeader, Loader } from "../../components"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom"
import { Autocomplete, Grid, IconButton, TextField } from "@mui/material"
import { COLORS } from "../../constants"
import { acceptInvitation } from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"

const InvitationAccept = () => {
  const { id } = useParams()
  const location = useLocation()

  // A function to parse query parameters
  const searchParams = new URLSearchParams(location.search)
  const orgName = searchParams.get("org_name") // Use the exact name of the query parameter

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
    position: "",
    status: "",
    grade: "",
    loading: false
  })

  useEffect(() => {
    handleInvitation()
  }, [])

  const handleInvitation = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem("token")

      await acceptInvitation(
        { unique_player_id: id, status: "Committed" },
        token
      )

      enqueueSnackbar(`Invitation committed successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } finally {
      setLoading(true)
    }
  }
  return (
    <div>
      <HomeHeader />

      <div
        style={{
          borderTopWidth: 1,
          borderTopColor: "#F0F1F2",
          borderTopStyle: "solid",
          marginTop: 80
        }}
      >
        <section
          className="container p-0 pt-3 bg-transparent"
          style={{
            width: 400,
            height: "100%"
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <p
                className="font-medium"
                style={{ fontSize: 18, marginTop: 20 }}
              >
                You accepted the invitation form {orgName} and committed to play
                for their organization.
              </p>
            </Grid>
            <Grid item xs={12}>
              <p className="font-medium" style={{ fontSize: 18 }}>
                Signup now and join this organization.
              </p>
            </Grid>
            <Grid item xs={12}>
              <AppButton
                title={"Home"}
                backgroundColor={COLORS.white}
                color={"#000000D9"}
                fontWeight={500}
                borderColor={"#D9D9D9"}
                onClick={() => navigate("/")}
              />
            </Grid>
          </Grid>
        </section>
      </div>
    </div>
  )
}

export default InvitationAccept
