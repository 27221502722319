import * as React from "react"
import { Container } from "@mui/material"
import { AdminLayout, Loader, UserTable } from "../../components"
import { useContext } from "react"
import AppContext from "../../Context"
import { useState } from "react"
import {
  banUser,
  deleteReportedUsers,
  getAllUsers,
  getReportedUsers,
  unBanUser
} from "../../api/admin"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import ReportedUserTable from "../../components/Admin/ReportedUserTable"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"

function AdminReportedUserContent() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const {} = useContext(AppContext)
  const [state, setState] = useState({
    loading: false,
    limit: 10,
    page: 0,
    count: 0,
    users: [],
    reportedUser: [],
    loadingDelete: false
  })
  const { users, limit, reportedUser, page, count, loading, loadingDelete } =
    state
  const [filteredList, setFilteredList] = useState()

  // Initialize filteredList after reportedUser has been initialized
  const [filteredUserList, setFilteredUserList] = useState(reportedUser)
  const token = localStorage.getItem("admintoken")
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }
  useEffect(() => {
    if (!token) {
      navigate("/webadmin/login")
      return
    }
    getData(true)
  }, [])
  useEffect(() => {
    setFilteredList(reportedUser)
  }, [reportedUser])
  useEffect(() => {
    setFilteredUserList(filteredList)
  }, [filteredList])
  const getData = async (resetPage, paged) => {
    const payload = `?limit=${limit}&offset=${resetPage ? 0 : paged}`
    await _getAllUsers("?limit=1000", token)
    await _getAllReportedUsers(payload)
  }

  const clickNext = newPage => {
    getData(false, newPage * 10)
    handleChange("page", newPage)
  }
  const _getAllUsers = async (payload, resetPage) => {
    try {
      handleChange("users", [])
      handleChange("loading", true)
      const res = await getAllUsers(payload, token)
      handleChange("users", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getAllReportedUsers = async (payload, resetPage) => {
    try {
      handleChange("reportedUser", [])
      handleChange("loading", true)
      const res = await getReportedUsers(payload, token)
      handleChange("reportedUser", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _deleteReportMessage = async (id, handleCloseDelete) => {
    try {
      handleChange("loadingDelete", true)
      await deleteReportedUsers(id, token)
      getData(false, page * 10)
      handleChange("loadingDelete", false)
      handleCloseDelete()
      enqueueSnackbar("Reported message has been Deleted", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _banUser = async (id, handleCloseBan) => {
    try {
      handleChange("loadingDelete", true)
      await banUser(id, token)
      getData(false, page * 10)
      handleChange("loadingDelete", false)
      enqueueSnackbar("User has been ban successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _unBanUser = async id => {
    try {
      handleChange("loadingDelete", true)
      await unBanUser(id, token)
      getData(false, page * 10)
      handleChange("loadingDelete", false)
      enqueueSnackbar("User has been Unban successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const filtered = value => {
    if (value) {
      // Add name field to reported users
      const usersWithNames = reportedUser.map(reported => {
        const matchingUser = users.find(user => user.id === reported.user)
        if (matchingUser) {
          return {
            ...reported,
            name: matchingUser.name
          }
        }
        return reported
      })

      // Filter the reported users based on search value
      const filteredList = usersWithNames?.filter(
        e => e?.name && e?.name?.toLowerCase()?.includes(value?.toLowerCase())
      )

      setFilteredUserList(filteredList)
    } else {
      setFilteredUserList(reportedUser)
    }
  }

  return (
    <div>
      <AdminLayout>
        {loading ? (
          <Loader />
        ) : (
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h5 className="mb-4">Reported Users</h5>
            <div className="searchInputHeaderBox">
              <input
                className="searchInput"
                onChange={e => filtered(e.target.value)}
                placeholder="Search..."
              />
              <div className="saerchIconHeaderDiv">
                <SearchGrey />
              </div>
            </div>
            <div className="mt-3">
              {filteredUserList && (
                <ReportedUserTable
                  _banUser={_banUser}
                  _unBanUser={_unBanUser}
                  users={users}
                  reportedUser={filteredUserList}
                  count={count}
                  page={page}
                  loadingDelete={loadingDelete}
                  clickNext={clickNext}
                  _deleteReportMessage={_deleteReportMessage}
                  loading={loading}
                  getData={getData} // Add getData as a prop
                />
              )}
            </div>
          </Container>
        )}
      </AdminLayout>
    </div>
  )
}

export default function AdminReportedUser() {
  return <AdminReportedUserContent />
}
