// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useState } from "react"
import {
  HomeHeader,
  AppButton,
  OrganizationTeamTable,
  AddReview,
  AddStats,
  AddCoachQuestionsReview,
  Loader,
  Post,
  QRCode,
  FollowUser
} from "../../components"
import { Grid, Divider, Box, Avatar } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import AvatarIcon from "../../assets/images/avatar.png"
import {
  addFollow,
  addPlayerToFav,
  checkCoachReviewStatus,
  createChannel,
  declineLink,
  getAllPosts,
  getChannels,
  getOrganizationProfile,
  getUserProfile,
  getUserProfileFollower,
  getUserProfileFollowing,
  removeFollow,
  removeFollower,
  removePlayerToFav,
  reviewsCoachCatScore,
  searchUserProfile,
  searchUserProfileOld
} from "../../api/auth"
import SeeMoreText from "../../components/Text/SeeMoreText"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import { useEffect } from "react"
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg"
import { COLORS } from "../../constants"
import { ReactComponent as MessageFill } from "../../assets/svg/MessageFill.svg"
import AddIcon from "@mui/icons-material/Add"
import LinkProfile from "../../components/GeneralModal/LinkPlayer"
import { getUsers } from "../../api/admin"
import DeleteIcon from "@mui/icons-material/Delete"
import { ReactComponent as QRcodeIcon } from "../../assets/svg/qrcode.svg"
import { ReactComponent as StarFill } from "../../assets/svg/StarFill.svg"
import { ReactComponent as FavWhite } from "../../assets/svg/favorite.svg"

export default function ParentProfile({ }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const [getUserLoader, setGetUserLoader] = useState(false)
  const { user, _getProfile, isPlayer, isParent } = useContext(AppContext)
  const [state, setState] = useState({
    showAll: false,
    reviewVisible: false,
    statsVisible: false,
    teams: [],
    loading: false,
    userData: null,
    questionReviews: [],
    allUsers: [],
    allChannels: [],
    videoPlaying: "",
    showComment: "",
    posts: [],
    reviewStatus: false,
    questionsReviewVisible: false,
    loadingChat: false,
    organizationData: null,
    followVisible: false,
    loadingRemove: "",
    loadingFollow: "",
    allPlayers: [],
    linkedPlayers: [],
    playerId: {},
    linkVisible: false,
    showQRCode: false,
    loadingFollow: false,
    followVisible: false,
    followVisibleType: "",
    loadingRemove: false,
    userProfileFollower: [],
    userProfileFollowing: [],
    currentUserFollower: [],
    currentUserFollowing: [],
    loadingFav: false
  })

  const {
    showAll,
    teams,
    loading,
    userData,
    videoPlaying,
    showComment,
    posts,
    allUsers,
    allChannels,
    showQRCode,
    questionReviews,
    reviewStatus,
    questionsReviewVisible,
    loadingChat,
    statsVisible,
    allPlayers,
    linkedPlayers,
    playerId,
    linkVisible,
    loadingFollow,
    followVisible,
    followVisibleType,
    loadingRemove,
    userProfileFollower,
    userProfileFollowing,
    currentUserFollower,
    currentUserFollowing,
    loadingFav,
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleCloseQRCode = () => {
    handleChange("showQRCode", false)
  }

  useEffect(() => {
    _getProfile()
    // getAllPlayers()
    getLinkedPlayers(id)
    if (id) {
      getProfileData()
      _getAllPosts()
      _getAllSharedUsers()
      _getChannels()
    }
  }, [id, linkVisible, showAll])

  // const getAllPlayers = async () => {
  //   try {
  //     handleChange("loading", true)
  //     const res = await searchUserProfile(
  //       "?limit=1000&offset=20&role=Player",
  //       token
  //     )
  //     handleChange("allPlayers", res?.data?.results)
  //     handleChange("loading", false)
  //   } catch (error) {
  //     handleChange("loading", false)
  //     enqueueSnackbar(getError(error), {
  //       variant: "error",
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "right"
  //       }
  //     })
  //   }
  // }
  const getLinkedPlayers = async () => {
    try {
      handleChange("loading", true)
      // const res = await searchUserProfile(`linked_relationships/`, token)
      let endpoint = `linked_relationships/`
      if (id) {
        endpoint += `?user_id=${id}`
      }
      const res = await searchUserProfileOld(endpoint, token)
      handleChange("linkedPlayers", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getAllPosts = async (noLoading) => {
    try {
      // handleChange("posts", [])
      handleChange("loading", noLoading ? false : true)
      const token = localStorage.getItem("token")
      const res = await getAllPosts(`?user__id=${id}`, token)
      handleChange("posts", res?.data?.results)
      // handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }
  const getProfileData = async (noLoading) => {
    try {
      handleChange("loading", noLoading ? false : true)
      const payload = `?user_id=${id}`
      const res = await getUserProfile(id, token)
      handleChange("userData", res?.data)
      handleChange("loadingFollow", "")
      handleChange("loading", false)
      const currentUserFollower = await getUserProfileFollower("", token)
      const currentUserFollowing = await getUserProfileFollowing("", token)
      const resFollower = await getUserProfileFollower(payload, token)
      const resFollowing = await getUserProfileFollowing(payload, token)
      handleChange("userProfileFollower", resFollower?.data)
      handleChange("userProfileFollowing", resFollowing?.data)
      handleChange("currentUserFollower", currentUserFollower?.data)
      handleChange("currentUserFollowing", currentUserFollowing?.data)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _createChannel = async () => {
    try {
      handleChange("loadingChat", true)
      const payload = {
        users: [Number(id), user?.id]
      }
      const res = await createChannel(payload)
      handleChange("loadingChat", false)
      navigate(`/messages/${res?.data?.id}`)
    } catch (error) {
      handleChange("loadingChat", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleCloseLink = () => {
    handleChange("linkVisible", false)
  }

  const handleOpenLink = type => {
    handleChange("linkVisible", true)
  }

  const handleClose = getTeam => {
    handleChange("statsVisible", false)
    handleChange("teamID", "")
    if (getTeam) {
      // getData(0)
    }
  }
  const _getAllSharedUsers = async () => {
    setGetUserLoader(true)
    try {
      const res = await getUsers("?limit=20&offset=20/", token)
      if (res) {
        handleChange("allUsers", res?.data?.results)
        setGetUserLoader(false)
      }
    } catch (error) {
      setGetUserLoader(false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getChannels = async () => {
    try {
      handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const declineLinkAction = async linkId => {
    try {
      const payload = {
        link_id: linkId
      }
      const res = await declineLink(payload, token)
      enqueueSnackbar(`Linking request deleted`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("showAll", !showAll)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("showAll", true)
    }
  }

  const _removeFollower = async f_id => {
    try {
      handleChange("loadingRemove", f_id)
      const token = localStorage.getItem("token")
      // if (user?.role === "Parent") {
      //   await removeFollower(
      //     `?user_id=${f_id}&player_id=${userData?.player?.id}`,
      //     token
      //   )
      // } else {
      await removeFollower(`?user_id=${f_id}`, token)
      // }
      getProfileData(true)
      handleChange("loadingRemove", "")
    } catch (error) {
      handleChange("loadingRemove", "")
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _addFollow = async (u_id, type) => {
    try {
      const f_id = u_id || id
      handleChange("loadingFollow", f_id)
      const token = localStorage.getItem("token")
      if (type === "unfollow") {
        // if (user?.role === "Parent") {
        //   await removeFollow(
        //     `?user_id=${f_id}&player_id=${userData?.id}`,
        //     token
        //   )
        // } else {
        await removeFollow(`?user_id=${f_id}`, token)
        // }
      } else {
        await addFollow(`?user_id=${f_id}`, token)
      }
      getProfileData(true, u_id)
    } catch (error) {
      handleChange("loadingFollow", "")
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleOpenFollow = type => {
    handleChange("followVisible", true)
    handleChange("followVisibleType", type)
  }

  const handleCloseFollow = () => {
    handleChange("followVisible", false)
    handleChange("followVisibleType", "")
  }

  const _addPlayerToFav = async () => {
    try {
      handleChange("loadingFav", true)
      const token = localStorage.getItem("token")
      if (userData?.is_favorite) {
        await removePlayerToFav(`?user_id=${id}`, token)
      } else {
        await addPlayerToFav(`?user_id=${id}`, token)
      }
      getProfileData(true)
      handleChange("loadingFav", false)
    } catch (error) {
      handleChange("loadingFav", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const isOwnProfile = id == user?.id

  return (
    <div>
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ paddingTop: "3rem" }}>
            <section className="container p-0 width60 bg-transparent">
              {/* <p className={"nameOrganization font-24 mt-4"}>
                {isOwnProfile ? "Your" : "Parent"} Profile
              </p> */}
              <Grid container alignItems={"center"} gap={5}>
                <Grid item>
                  <img
                    src={
                      userData?.parent?.picture
                        ? userData?.parent?.picture
                        : AvatarIcon
                    }
                    className={"profileIMG"}
                  />
                  <Box
                    sx={{
                      marginTop: -4,
                      marginLeft: 10,
                      marginBottom: 3
                    }}
                    className="Ball-Responsive"
                  >
                    <Avatar
                      src={userData?.parent?.main_sport?.image}
                      sx={{ width: 60, borderRadius: 2, height: 60 }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <p className="parent_name">{userData?.name}</p>
                  <div className="d-flex">
                    <div
                      className="mr-5 cursor"
                      onClick={() => handleOpenFollow("follower")}
                    >
                      <div className="w-label text-black">Followers</div>
                      <div className="w-value text-black">
                        {userData?.followers_count}
                      </div>
                    </div>
                    <div
                      className="cursor"
                      onClick={() => handleOpenFollow("following")}
                    >
                      <div className={"w-label text-center text-black"}>Following</div>
                      <div className="w-value text-black">
                        {userData?.following_count}
                      </div>
                    </div>
                  </div>
                  {
                    user?.id != id &&
                    <div className="d-flex">
                      <AppButton
                        color={"#000000D9"}
                        backgroundColor={"#FFFFFF"}
                        borderColor={"#D9D9D9"}
                        width={100}
                        height={35}
                        className={"mt-2"}
                        fontWeight={"400"}
                        title={
                          <div
                            className="d-flex align-items-center"
                            style={{ marginLeft: -5 }}
                          >
                            <StarFill
                              style={{ marginTop: -3 }}
                              width={20}
                            />{" "}
                            <span className="ml-1">
                              {userData?.is_following
                                ? "Unfollow"
                                : "Follow"}
                            </span>
                          </div>
                        }
                        onClick={() =>
                          _addFollow(
                            userData?.id,
                            userData?.is_following ? "unfollow" : "follow"
                          )
                        }
                        loading={!followVisible && loadingFollow}
                      />
                      <AppButton
                        width={100}
                        color={"#000000D9"}
                        backgroundColor={"#FFFFFF"}
                        borderColor={"#D9D9D9"}
                        className={"ml-3 mt-2"}
                        height={35}
                        title={
                          <div
                            className="d-flex align-items-center"
                            style={{ marginLeft: -5 }}
                          >
                            <FavWhite style={{ marginTop: -2 }} />{" "}
                            <span
                              className={
                                userData?.is_favorite
                                  ? "ml-1 text-black"
                                  : "ml-1 text-black"
                              }
                            >
                              {userData?.is_favorite
                                ? "Unfavorite"
                                : "Favorite"}
                            </span>
                          </div>
                        }
                        fontWeight={"400"}
                        onClick={_addPlayerToFav}
                        loading={loadingFav}
                      />
                    </div>
                  }
                  {/* <div
                    className="parentEmailNumber"
                    style={{ color: "rgba(0, 0, 0, 0.45)", gap: 10 }}
                  >
                    <span>{userData?.email}</span>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        borderRightWidth: 3,
                        borderColor: "rgba(0, 0, 0, 0.45)"
                      }}
                    />
                    <span>{userData?.phone_number}</span>
                  </div> */}
                </Grid>
                <Grid
                  item
                  // container
                  direction={"column"}
                  // alignItems={"flex-end"}
                  sx={{ mb: 4, mt: 0 }}
                // xs={12}
                // md={4}
                >
                  {isOwnProfile && (
                    <div className="d-flex align-items-center">
                      {userData?.parent?.qr_code && (
                        <Grid>
                          <AppButton
                            width={35}
                            height={35}
                            onClick={() => handleChange("showQRCode", true)}
                            borderRadius={35}
                            className={"min-width-remove mr-2 mb-3"}
                            title={
                              <QRcodeIcon style={{ marginTop: -3 }} width={20} />
                            }
                          />
                        </Grid>
                      )}
                      <AppButton
                        width={35}
                        height={35}
                        borderRadius={35}
                        onClick={() => navigate(`/parent-profile/${id}/edit`)}
                        backgroundColor={COLORS.primary}
                        className={"min-width-remove mb-3"}
                        title={<Edit />}
                      />
                    </div>
                  )}
                  {user?.id != id && (
                    <AppButton
                      width={120}
                      height={35}
                      className={"ml-3 mt-2"}
                      loading={loadingChat}
                      color={"#000000D9"}
                      backgroundColor={"#FFFFFF"}
                      borderColor={"#D9D9D9"}
                      onClick={_createChannel}
                      title={
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: -5 }}
                        >
                          <MessageFill style={{ marginTop: -3 }} width={20} />{" "}
                          <span className="ml-1">Message</span>
                        </div>
                      }
                      fontWeight={"400"}
                    />
                  )}
                </Grid>
              </Grid>
            </section>
          </div>
          <section className="container p-0 width60 bg-transparent">
            <div>
              <p className="font-bold font-18">Bio</p>
              <span className="bioOrganization">
                <SeeMoreText text={userData?.parent?.bio} maxLength={275} />
              </span>
            </div>
          </section>
          <section className="container pb-3 mb-0 bg-transparent">
            <Grid item xs={6} md={2}>
              <div className="text-black font-24 font-500">Activities</div>
              <Divider className="mt-4" />
              {posts?.map((post, index) => (
                <Post
                  key={index}
                  state={state}
                  showComment={showComment}
                  handleChange={handleChange}
                  post={post}
                  getData={_getAllPosts}
                  videoPlaying={videoPlaying}
                  allUsers={allUsers}
                  getUserLoader={getUserLoader}
                  allChannels={allChannels}
                  index={index}
                />
              ))}
            </Grid>
          </section>

          {/* <section className="container width70 p-0 pl-3 bg-transparent">
            <Grid container className="reviewBox">
              {questionReviews?.map((item, index) => (
                <Grid
                  key={index}
                  container
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  item
                  xs={6}
                  md={4}
                  lg={1.7}
                  style={{
                    padding: "10px 10px",
                    border:
                      index === questionReviews?.length - 1
                        ? "none"
                        : "1px solid rgba(0, 0, 0, 0.06)"
                  }}
                >
                  {" "}
                  <img
                    src={
                      item[item?.display_icon]
                        ? item[item?.display_icon]
                        : "https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"
                    }
                    className="reviewImage"
                  />
                  <div className="font-bold text-black text-center">
                    {item?.name}
                  </div>
                </Grid>
              ))}
            </Grid>
          </section> */}
          {/* <div className="">
            <section className="container width70 p-0 bg-transparent">
              <Grid item xs={12} className="">
                <div className="font-bold mb-3 text-black">
                  Personal information
                </div>
              </Grid>
              <Grid container className="border p-3">
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Name</div>
                  <div className="valueCoach">{userData?.name}</div>
                </Grid>
                <Grid item xs={12} className="p-3">
                  <div className="labelCoach">Email</div>
                  <div className="valueCoach">{userData?.email}</div>
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Phone Number</div>
                  <div className="valueCoach">{userData?.phone_number}</div>
                </Grid>
              </Grid>
              <Grid container justifyContent={"center"}>
                <AppButton
                  title={
                    isOwnProfile
                      ? "Post Tournament"
                      : "Enquiry Training Sessions"
                  }
                  backgroundColor={COLORS.primary}
                  color={COLORS.white}
                  width={isOwnProfile ? 150 : 200}
                  height={40}
                  className={"mt-4 mb-4"}
                />
              </Grid>
            </section>
          </div> */}
        </>
      )}

      <QRCode visible={showQRCode} type={'parent'} parent={userData} handleClose={handleCloseQRCode} />
      <LinkProfile
        visible={linkVisible}
        loadingRemove={loadingRemove}
        loading={loadingFollow}
        handleClose={handleCloseLink}
        data={allPlayers}
      />

      <AddStats
        visible={statsVisible}
        playerId={playerId}
        handleClose={handleClose}
      />
      <FollowUser
        visible={followVisible}
        _addFollow={_addFollow}
        loadingRemove={loadingRemove}
        _removeFollower={_removeFollower}
        loading={loadingFollow}
        userProfileFollower={userProfileFollower}
        userProfileFollowing={userProfileFollowing}
        currentUserFollower={currentUserFollower}
        currentUserFollowing={currentUserFollowing}
        followVisibleType={followVisibleType}
        handleClose={handleCloseFollow}
      />
    </div>
  )
}
