// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useState } from "react"
import {
  HomeHeader,
  AppButton,
  AppInput,
  Label,
  GeneralModal,
  Feedback
} from "../../components"
import { Grid } from "@mui/material"
import { COLORS } from "../../constants"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import { useNavigate, useParams } from "react-router-dom"
import { ReactComponent as TermsIcon } from "../../assets/svg/settings/terms.svg"
import { ReactComponent as PPIcon } from "../../assets/svg/settings/PP.svg"
import { ReactComponent as PNIcon } from "../../assets/svg/settings/PN.svg"
import { ReactComponent as ENIcon } from "../../assets/svg/settings/EN.svg"
import { ReactComponent as CPIcon } from "../../assets/svg/settings/CP.svg"
import { ReactComponent as SupportIcon } from "../../assets/svg/settings/Support.svg"
import { ReactComponent as NotificationIcon } from "../../assets/svg/settings/Notification.svg"
import { ReactComponent as DeleteAccountIcon } from "../../assets/svg/settings/DeleteAccount.svg"
import { ReactComponent as LinkRequestIcon } from "../../assets/svg/settings/LR.svg"
import { ReactComponent as DeleteAccountModalIcon } from "../../assets/svg/heart_broken.svg"
import AppContext from "../../Context"
import { ReactComponent as RightChevron } from "../../assets/svg/RightChevron.svg"
import AvatarIcon from "../../assets/images/avatar.png"
import { deleteAccount } from "../../api/auth"

export default function Settings({}) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const {
    resetState,
    isOrganization,
    organizationData,
    user,
    isParent,
    isPlayer,
    userProfile
  } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const [state, setState] = useState({
    loading: false,
    loadingDelete: false,
    deleteVisible: false,
    feedbackVisible: false
  })

  const { loading, loadingDelete, deleteVisible, feedbackVisible } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const onclick = route => {
    if (route) {
      navigate(route)
    }
  }

  const handleCloseDelete = () => {
    handleChange("deleteVisible", false)
  }

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)

      handleChange("loading", false)
      navigate("/teams")
      enqueueSnackbar(`Team has been created`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    resetState()
    navigate("/")
  }

  const _deleteUser = async () => {
    try {
      handleChange("loadingDelete", true)
      await deleteAccount(user?.id, token)
      logout()
      handleChange("loadingDelete", false)
      enqueueSnackbar("your account has been Deleted", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleCloseFeedback = () => {
    handleChange("feedbackVisible", false)
  }

  const list = [
    {
      title: isOrganization
        ? organizationData?.organization_name
        : user?.name || "Guest",
      icon: (
        <img
          src={
            isPlayer
              ? userProfile?.player?.picture
              : isParent
                ? userProfile?.parent?.picture
                : isOrganization
                  ? organizationData?.logo
                  : user?.profile?.picture || AvatarIcon
          }
          className="settinguserprofile"
        />
      )
    },
    // { title: "Notifications", icon: <NotificationIcon /> },
    // { title: "Push Notifications", icon: <PNIcon /> },
    // { title: 'Linking Requests',icon: <img src={LINKING_IMAGE} height="35" alt="Link Request Icon" />,route: '/linking-request' },
    {
      title: "Linking Requests",
      icon: <LinkRequestIcon />,
      route: "/linking-request"
    },
    // { title: "Email Notifications", icon: <ENIcon /> },
    {
      title: "Privacy Policy",
      icon: <PPIcon />,
      onClick: () =>
        window.open(
          "https://app.termly.io/policy-viewer/policy.html?policyUUID=cd25d1e5-02e0-43c1-a6cc-8252430a55bd",
          "_blank"
        )
      // route: "/privacy"
    },
    {
      title: "Cookie policy",
      icon: <PPIcon />,
      onClick: () =>
        window.open(
          "https://app.termly.io/policy-viewer/policy.html?policyUUID=a1833435-d333-4a58-88b8-d2132e361151",
          "_blank"
        )
      // route: "/privacy"
    },
    {
      title: "Terms & Conditions",
      icon: <TermsIcon />,
      onClick: () =>
        window.open(
          "https://app.termly.io/policy-viewer/policy.html?policyUUID=70b24cbf-084b-412a-8305-e4fca0acbe34",
          "_blank"
        )
      // route: "/terms"
    },
    {
      title: "FAQ",
      icon: <TermsIcon />,
      onClick: () =>
        window.open(
          "https://thesportsportal.com/faq",
          "_blank"
        )
      // route: "/terms"
    },
    {
      title: "Change Password",
      icon: <CPIcon />,
      route: "/settings/change-password"
    },
    {
      title: "Support/Send Feedback",
      icon: <SupportIcon />,
      onClick: () => handleChange("feedbackVisible", true)
    },
    {
      title: "Delete Account",
      icon: <DeleteAccountIcon />,
      onClick: () => handleChange("deleteVisible", true)
    }
  ]

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container pb-3 mb-0 bg-transparent">
        <Grid className="mb-3" container justifyContent={"space-between"}>
          <div className="pageHeading">Settings</div>
        </Grid>
        <Grid container spacing={3}>
          <Grid container spacing={3} item xs={12} md={8}>
            <Grid item xs={12} md={10}>
              {list
              ?.filter((item) => {
                if (user?.role == "Player") {
                  return true; // Display all items for "Player" role
                } else {
                  return item?.title != "Linking Requests"; // Exclude "Linking Requests" for other roles
                }
              })
              ?.map((item, index) => (
                <div
                  key={index}
                  className="googleAuth settingColor"
                  onClick={() =>
                    item?.onClick ? item?.onClick() : onclick(item?.route)
                  }
                >
                  <div className="d-flex align-items-center">
                    {item?.icon}
                    <span className="ml-3 font-bold">{item?.title}</span>
                  </div>
                  <RightChevron />
                </div>
              ))}
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Grid>
      </section>
      <Feedback visible={feedbackVisible} handleClose={handleCloseFeedback} />
      <GeneralModal
        visible={deleteVisible}
        icon={<DeleteAccountModalIcon />}
        handlePress={_deleteUser}
        loading={loadingDelete}
        handleClose={handleCloseDelete}
        ActionButtonText={"Delete Account"}
        title={"Delete Account"}
        description={
          "Are you sure you want to delete this account? You won’t be able to recover it after the deletion of this account"
        }
      />
    </div>
  )
}
