import { IconButton, Popover } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { EMOJI_ICONS } from '../../utils/mixed';
import EmojiPicker, { Categories } from 'emoji-picker-react';

export default function ChatContent({
    message,
    index,
    isMessageToFocus,
    messageRefs,
    user,
    printName,
    getOtherUser,
    currentChannelData,
    isRoute,
    handleUserClick,
    loadingReact,
    _addReaction
}) {
    const [mouseEnter, setMouseEnter] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [showEmojiBoard, setShowEmojiBoard] = useState(false)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setShowEmojiBoard(false)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    return (
        <div
            onMouseEnter={() => setMouseEnter(true)}
            onMouseLeave={() => setMouseEnter(false)}
            key={`message-${index}`}
            ref={el => isMessageToFocus && (messageRefs.current[message.id] = el)} // Store ref for scrolling
            className={
                `${message?.sender?.id !== user?.id
                    ? "otherMessage"
                    : "ownMessage"} ${isMessageToFocus ? "messageToFocus" : ""}`
            }
        >
            {message?.sender?.id !== user?.id && (
                <img
                    src={
                        printName(getOtherUser([message?.sender]))
                            .ProfileDP
                    }
                    onClick={() =>
                        message?.sender?.id !== user?.id &&
                        handleUserClick(
                            getOtherUser([message?.sender])
                        )
                    }
                    className={"c-pointer mr-4 userprofile"}
                />
            )}
            <div className="">
                {currentChannelData?.group_name !==
                    "Unnamed Chat Group" &&
                    message?.sender?.id !== user?.id && (
                        <div className="mb-1 font-bold font-12">
                            {
                                printName(
                                    getOtherUser([message?.sender])
                                ).ProfileName
                            }
                        </div>
                    )}
                <div className={
                    message?.sender?.id !== user?.id
                        ? "otherOuterMessageTextBox"
                        : "outerMessageTextBox"
                }>
                    <div
                        className={
                            message?.sender?.id !== user?.id
                                ? "otherMessageTextBox"
                                : "messageTextBox"
                        }
                    >
                        {!!message?.file && (
                            <img
                                src={message?.file}
                                className="fileMessage"
                            />
                        )}
                        {isRoute ? (
                            <a
                                href={message.text}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: `${message?.sender?.id === user?.id ? "#fff" : ""}`
                                }}
                            >
                                {message?.text || message?.message}
                            </a>
                        ) : (
                            <div>
                                {message?.text || message?.message}
                            </div>
                        )}
                    </div>
                    {
                        mouseEnter &&
                        <>
                            <div className="ml-2 mr-2">
                                <IconButton className="" onClick={handleClick}>
                                    <AddReactionIcon />
                                </IconButton>
                            </div>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                classes={{ paper: "dropdown-paper" }}
                                // sx={{ width: 250 }}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right"
                                }}
                            >
                                <div className='d-flex align-items-center'>
                                    {
                                        EMOJI_ICONS.map((emoji, index) => (
                                            <IconButton key={index} onClick={() => {
                                                loadingReact === message?.id ? console.log()
                                                    : _addReaction(emoji.value, message?.id, handleClose)
                                            }}>
                                                <span className={emoji?.key === 'heart' ? "heartButton" : ""}>{emoji.value}</span>
                                            </IconButton>
                                        ))
                                    }
                                    <IconButton key={index} onClick={() => {
                                        setAnchorEl(null)
                                        setShowEmojiBoard(!showEmojiBoard)
                                    }}>
                                        <AddReactionIcon />
                                    </IconButton>
                                </div>

                            </Popover>
                            {showEmojiBoard && (
                                <div className="EmojiPicker">
                                    <EmojiPicker
                                        onEmojiClick={(emoji) => _addReaction(emoji?.emoji, message?.id, handleClose)}
                                        categories={[
                                            { category: Categories.SMILEYS_PEOPLE },
                                            { category: Categories.ACTIVITIES },
                                            { category: Categories.ANIMALS_NATURE },
                                            { category: Categories.FOOD_DRINK },
                                            { category: Categories.OBJECTS },
                                            { category: Categories.SYMBOLS },
                                            { category: Categories.TRAVEL_PLACES }
                                        ]}
                                        emojiStyle="facebook"
                                        showPreview={true}
                                    />
                                </div>
                            )}
                        </>
                    }
                </div>
                <div className="timetoken">
                    {moment(message?.timetoken).fromNow()}
                </div>
                <div className={message?.sender?.id !== user?.id ? "reactionContainer1" : 'reactionContainer'}>
                    {
                        message?.reactions?.map((react, index) => (
                            <div key={index} className={react?.emoji === '❤️' ? "reactiondiv heartButton" : "reactiondiv"}>
                                {react?.emoji} {react?.count}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}