import React, { useContext, useEffect, useState } from "react"
import { AppButton, HomeHeader, Loader } from "../../components"
import {
  Button,
  Grid,
  IconButton,
  Paper,
  experimental_extendTheme
} from "@mui/material"
import { ReactComponent as AddPlayerIcon } from "../../assets/svg/add-player.svg"
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete.svg"
import { COLORS } from "../../constants"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate, useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import { DataGrid } from "@mui/x-data-grid"
import AppSelect from "../../components/AppSelect"
import RemovePlayer from "../../components/GeneralModal/RemoveUser"
import { ADDPLAYER, PLAYERPROFILE } from "../../constants/routes"
import { getError } from "../../utils/getError"
import {
  addMemberChannel,
  assignPotentialPlayer,
  getOrganizationTeam,
  getTeamPlayers,
  getUserProfile,
  leaveGroup,
  updateTeamPlayer
} from "../../api/auth"
import { useSnackbar } from "notistack"
import AppContext from "../../Context"

const Roster = () => {
  const { team, organization } = useParams()

  const { user, organizationData } = useContext(AppContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [removeModal, setRemoveModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [statusSelected, setStatusSelected] = useState("Current Players")
  const [state, setState] = useState({
    statsVisible: false,
    teamID: "",
    teams: [],
    loading: false,
    limitTeam: 10,
    pageTeam: 0,
    offsetTeam: 0,
    organizationData: null,
    switchTeam: null,
    teamPlayers: null,
    pastPlayers: null,
    assignLoading: false
  })

  const {
    teams,
    loading,
    limitTeam,
    pageTeam,
    offsetTeam,
    // organizationData,
    switchTeam,
    teamPlayers,
    pastPlayers,
    assignLoading
  } = state
  const currentTeam = teams?.results?.find(v => v.id == team)
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 160,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: params => {
        return (
          <div
            className="text-black"
            onClick={() =>
              params?.row?.player?.user?.id &&
              navigate(
                PLAYERPROFILE?.replace(":id", params?.row?.player?.user?.id)
              )
            }
          >
            <img
              src={params?.row?.player?.picture}
              className={"c-pointer userprofile"}
            />
            {params?.row?.name || params?.row?.player?.user?.name}
          </div>
        )
      }
    },
    {
      field: "jersey_number",
      headerName: "Jersey Number",
      width: 160,
      sortable: false,

      align: "center",
      headerAlign: "center",
      valueGetter: params =>
        `${params?.row?.player?.preferred_jersey_number || ""}`
    },
    {
      field: "grade",
      headerName: "Grade",
      sortable: false,

      align: "center",
      headerAlign: "center",
      width: 160,
      valueGetter: params => `${params?.row?.player?.grade?.name || ""}`
    },
    {
      field: "primary_position",
      headerName: "Primary Position",
      sortable: false,

      align: "center",
      headerAlign: "center",
      width: 160,
      valueGetter: params =>
        `${params?.row?.player?.first_position_play?.name || ""}`
    }
  ]

  if (statusSelected == "Current Players" && (organizationData?.id?.toString() === organization || currentTeam?.coaches?.user?.id === user?.id)) {
    // columns.push({
    //   field: "phone_number",
    //   headerName: "Phone Number",
    //   sortable: false,

    //   align: "center",
    //   headerAlign: "center",
    //   width: 160,
    //   valueGetter: params => `${params.row?.phone_number || ""}`
    // })
    columns.push({
      field: "actions",
      headerName: "Actions",
      sortable: false,

      align: "center",
      headerAlign: "center",
      width: 440,
      renderCell: params => (
        <Grid
          container
          spacing={2}
          alignSelf={"center"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item>
            <AppSelect
              data={teams?.results?.map(v => {
                return {
                  title: v.team_name,
                  value: v.id
                }
              })}
              value={params.row?.team?.id}
              onChange={e => handleAssign(e.target.value, [params.row.id])}
            />
          </Grid>
          <Grid item>
            <AppButton
              onClick={() => setRemoveModal([params.row.id])}
              height={30}
              className={"min-width-remove"}
              title={"Remove Player"}
              color={COLORS.primary}
              backgroundColor={"transparent"}
              fontWeight={400}
            />
          </Grid>
        </Grid>
      )
    })
  }

  const getTeams = async nextPage => {
    try {
      handleChange("teams", null)
      handleChange("loading", true)
      const offset = limitTeam * nextPage
      const token = localStorage.getItem("token")
      const res = await getOrganizationTeam(
        `?organization__id=${organization}&limit=${limitTeam}&offset=${offsetTeam}`,
        token
      )

      const teamPlayerData = await getTeamPlayers(
        `${team}&is_past_player=false`,
        token
      )
      const pastPlayerData = await getTeamPlayers(
        `${team}&is_past_player=true`,
        token
      )
      handleChange("teamPlayers", teamPlayerData?.data)
      handleChange("pastPlayers", pastPlayerData?.data)
      handleChange("teams", res?.data)
      handleChange("offset", offset)
      handleChange("pageTeam", nextPage)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  // const getProfileData = async () => {
  //   try {
  //     const token = localStorage.getItem("token")
  //     handleChange("loading", true)
  //     const res = await getUserProfile(organization, token)
  //     handleChange("organizationData", res?.data?.organization)
  //     // handleChange("loading", false)
  //   } catch (error) {
  //     handleChange("loading", false)
  //     enqueueSnackbar(getError(error), {
  //       variant: "error",
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "right"
  //       }
  //     })
  //   }
  // }

  useEffect(() => {
    getTeams()
    // getProfileData()
  }, [])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleSwitchTeam = e => {
    handleChange("switchTeam", e)
  }

  const handleCloseModal = () => {
    getTeams()
    setRemoveModal(false)
  }

  const handleAssign = async (teamId, rows) => {
    try {

      handleChange("assignLoading", true)
      const token = localStorage.getItem("token")

      await updateTeamPlayer({ team_players_ids: rows, team_id: teamId }, token)
      rows?.forEach(async element => {
        if (element && teamPlayers?.results?.some(e => e?.id === element)) {
          const foundTeam = teamPlayers?.results?.find(e => e?.id === element)
          if (foundTeam?.team?.chat_channel) {
            const payload = {
              channel: foundTeam?.team?.chat_channel,
              users: [foundTeam?.player?.user?.id]
            }
            await leaveGroup(payload)
          }
        }
        if (element && teams?.results?.some(e => e?.id === teamId)) {
          const foundTeam = teams?.results?.find(e => e?.id === teamId)
          const foundUser = teamPlayers?.results?.find(e => e?.id === element)
          if (foundTeam?.chat_channel) {
            const payload = {
              channel: foundTeam?.chat_channel,
              users: [foundUser?.player?.user?.id]
            }
            const res = await addMemberChannel(payload)
          }
        }
      });
      getTeams(null, false)
      enqueueSnackbar(`User has been assigned to team successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } finally {
      handleChange("assignLoading", false)
    }
  }

  return (
    <div>
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <section className="container p-0 pt-3 bg-transparent">
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <Grid container spacing={3} alignItems={"center"}>
                    <Grid item>
                      <IconButton
                        aria-label="delete"
                        size=""
                        sx={{
                          bgcolor: COLORS.white,
                          borderRadius: 2,
                          color: "#222"
                        }}
                        onClick={() => navigate(-1)}
                      >
                        <ArrowBackIcon color="#222" />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <p className="font-medium font-18">
                        {currentTeam?.team_name}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item>
                  <AppButton
                    color={COLORS.white}
                    fontWeight={400}
                    backgroundColor={COLORS.primary}
                    height={35}
                    title={
                      <div>
                        <AddPlayerIcon
                          style={{ marginBottom: 3, marginRight: 5 }}
                        />
                        Add Player
                      </div>
                    }
                    onClick={() =>
                      navigate(ADDPLAYER?.replace(":id", organization))
                    }
                  />
                </Grid> */}
              </Grid>
              <Grid container sx={{ mt: 3, mb: 3 }}>
                <Grid item>
                  <div className="group-btn-container">
                    <div
                      className={`group-btn ${statusSelected == "Current Players"
                        ? "group-btn-selected"
                        : ""
                        }`}
                      onClick={() => setStatusSelected("Current Players")}
                    >
                      Current Players
                    </div>
                    <div
                      className={`group-btn ${statusSelected == "Current Players"
                        ? ""
                        : "group-btn-selected"
                        }`}
                      onClick={() => setStatusSelected("Past Players")}
                    >
                      Past Players
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Paper variant="outlined" sx={{ borderColor: "#0000000F" }}>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ paddingLeft: 2, paddingRight: 2, height: 80 }}
                >
                  <Grid item>
                    <p style={{ fontSize: 28, fontWeight: "500" }}>
                      {statusSelected}
                    </p>
                  </Grid>
                  <Grid item>
                    {(statusSelected === "Current Players" && (organizationData?.id?.toString() === organization || currentTeam?.coaches?.user?.id === user?.id)) && (
                      <Grid container spacing={1} sx={{ p: 1 }}>
                        <Grid item>
                          <AppSelect
                            data={teams?.results?.map(v => {
                              return {
                                title: v.team_name,
                                value: v.id
                              }
                            })}
                            value={switchTeam}
                            onChange={e =>
                              handleAssign(e.target.value, selectedRows)
                            }
                          />
                        </Grid>
                        <Grid item>
                          <AppButton
                            color={"#FF4D4F"}
                            fontWeight={400}
                            height={35}
                            variant="outlined"
                            borderColor={"#FF4D4F"}
                            title={
                              <div>
                                <DeleteIcon
                                  style={{ marginBottom: 3, marginRight: 7 }}
                                />
                                Remove
                              </div>
                            }
                            onClick={() => setRemoveModal(selectedRows)}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <DataGrid
                      onRowSelectionModelChange={(e, i) => {
                        setSelectedRows(e)
                      }}
                      rows={
                        (statusSelected === "Current Players"
                          ? teamPlayers?.results
                          : pastPlayers?.results) || []
                      }
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10
                          }
                        }
                      }}
                      pageSizeOptions={[10]}
                      checkboxSelection={statusSelected === "Current Players"}
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnMenu
                      // onPaginationModelChange={e =>
                      //   console.log(e, "onPaginationModelChange")
                      // }
                      style={{
                        borderWidth: 1,
                        minHeight: 150
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </section>
          </div>
        </>
      )}
      <RemovePlayer visible={removeModal} teamPlayers={teamPlayers} handleClose={handleCloseModal} />
    </div>
  )
}

export default Roster
