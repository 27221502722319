import React, { useContext, useEffect, useState } from 'react'
import { AppButton, AppInput } from '..'
import {
    Box, Grid, Modal, Checkbox, CircularProgress,
    Typography,
    Popover,
    Autocomplete,
    TextField
} from '@mui/material'
import { COLORS } from '../../constants';
import { ReactComponent as Cross } from '../../assets/svg/Cross.svg'
import { addMemberChannel, createChannel, getOrganizationTeam, getUserProfileByFilters, searchUserProfile, sendFeedback, updateChannel } from '../../api/auth';
import { useSnackbar } from 'notistack';
import { getError } from '../../utils/getError';
import AppContext from '../../Context';
import { useNavigate, useParams } from 'react-router-dom';
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"
import { ReactComponent as Down } from "../../assets/svg/downBlack.svg"
import { getPayload } from '../../utils/formattedPayload';

function MessageModal({ visible, type, handleClose: handleCloseMain, _getChannels, currentChannelData, _getChannelMessages }) {
    const { enqueueSnackbar } = useSnackbar()

    const { id } = useParams()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null)
    const { user } = useContext(AppContext)
    const token = localStorage.getItem("token")
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        borderRadius: 6,
        height: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [state, setState] = useState({
        group_name: '',
        loading: false,
        loadingChat: false,
        list: [],
        filteredList: [],
        users: [],
        members: [],
        userList: [],
        loadingUsers: false,
        is_player_view: true,
        is_parent_view: true,
        is_coach_view: true,
        is_organization_view: true,
        searchText: "",
        committed_to_organization_custom: "",
        committed_to_organization: [],
    })
    const {
        group_name,
        filteredList,
        list,
        loadingChat,
        users,
        members,
        loadingUsers,
        is_player_view,
        is_parent_view,
        is_coach_view,
        is_organization_view,
        userList,
        searchText,
        committed_to_organization_custom,
        committed_to_organization
    } = state

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const _handleClose = () => {
        setAnchorEl(null)
    }

    const handleClose = () => {
        handleChange("committed_to_organization", [])
        handleChange('searchText', '')
        handleChange("list", [])
        handleChange("filteredList", [])
        handleCloseMain()
    }

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    useEffect(() => {
        _getProfile(searchText, committed_to_organization)
    }, [is_coach_view, is_organization_view, is_parent_view, is_player_view, visible])

    useEffect(() => {
        if (type === "changeName") {
            handleChange('group_name', currentChannelData?.group_name)
        }
    }, [type, currentChannelData])

    const _getProfileDropdown = async searchText => {
        try {
            // handleChange("searchText", searchText)
            const token = localStorage.getItem("token")
            if (searchText) {
                const res = await getOrganizationTeam(
                    `?search=${searchText}`,
                    token
                )
                handleChange(
                    "userList",
                    res?.data?.results.map(p => {
                        return {
                            title: p?.team_name,
                            value: p?.id,
                            ...p
                        }
                    })
                )
            }
        } catch (error) {
            const errorText = Object.values(error?.response?.data)
            alert(`Error: ${errorText[0]}`)
        }
    }

    const onInputChange = (event, value, reason) => {
        if (value != 'undefined') {
            handleChange("committed_to_organization_custom", value)
            _getProfileDropdown(value)
        } else {
            handleChange("committed_to_organization_custom", '')
            //  setOptions([])
        }
    }

    const _getProfile = async (searchText, team_id) => {
        try {

            handleChange("searchText", searchText)
            if (visible) {
                handleChange("loadingUsers", true)
                handleChange("filteredList", [])
                const token = localStorage.getItem("token")
                let payload = `?name=${searchText}`
                if (is_coach_view) {
                    payload = `${payload}&roles=Coach`
                }
                if (is_organization_view) {
                    payload = `${payload}&roles=Organization`
                }
                if (is_player_view) {
                    payload = `${payload}&roles=Player`
                }
                if (is_parent_view) {
                    payload = `${payload}&roles=Parent`
                }
                if (team_id?.length > 0) {
                    for (let i = 0; i < team_id.length; i++) {
                        const element = team_id[i];
                        payload = `${payload}&team_id=${element?.value}`
                    }

                }
                const res = await getUserProfileByFilters(payload, token)
                handleChange("list", res?.data?.results)
                handleChange("loadingUsers", false)
                handleChange("filteredList", res?.data?.results)
            }
        } catch (error) {
            handleChange("loadingUsers", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const handleUserClick = (user) => {
        handleClose()
        const role = user?.role
        const route = role === 'Player' ? `/player-profile/${user?.id}` : role === 'Organization' ? `/organization-profile/${user?.id}` : `/parent-profile/${user?.id}`
        navigate(route)
    }

    const _createChannel = async () => {
        try {
            handleChange("loadingChat", true)
            const payload = {
                users: [...users, user?.id],
                group_name
            }
            const res = await createChannel(payload)
            handleChange("loadingChat", false)
            _getChannels()
            handleClose(false)
            navigate(`/messages/${res?.data?.id}`)
        } catch (error) {
            handleChange("loadingChat", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }
    const _updateChannel = async () => {
        try {
            handleChange("loadingChat", true)
            const payload = {
                group_name
            }
            const res = await updateChannel(id, payload)
            handleChange("loadingChat", false)
            _getChannels()
            _getChannelMessages()
            handleClose(false)
        } catch (error) {
            handleChange("loadingChat", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const _addMemberChannel = async () => {
        try {
            handleChange("loadingChat", true)
            const payload = {
                channel: id,
                users: members
            }
            const res = await addMemberChannel(payload)
            handleChange("loadingChat", false)
            _getChannels()
            _getChannelMessages()
            handleClose(false)
            handleChange("members", [])
            enqueueSnackbar(members?.length > 1 ? "Members has been added" : "Member has been added", {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        } catch (error) {
            handleChange("loadingChat", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const removeExistingUsers = (users) => {
        return users.filter(function (objFromA) {
            return !currentChannelData?.users?.find(function (objFromB) {
                return objFromA.id === objFromB.id
            })
        })
    }

    const printName = (user) => {
        const isPlayer = user?.role === "Player"
        const isParent = user?.role === "Parent"
        const isCoach = user?.role === "Coach"
        const isOrganization = user?.role === "Organization"
        const ProfileDP = isPlayer
            ? user?.player?.picture || AvatarIcon
            : isParent
                ? user?.parent?.picture || AvatarIcon
                : isOrganization
                    ? user?.organization?.logo || AvatarIcon 
                    : isCoach
                        ? user?.coach?.picture || AvatarIcon
                        : user?.player?.picture || AvatarIcon
        const ProfileName = isOrganization
            ? user?.organization?.organization_name
            : user?.name || "Guest"

        return { ProfileName, ProfileDP }
    }

    const open = Boolean(anchorEl)
    const _id = open ? "simple-popover" : undefined
    const currentUser = id == user?.id
    const currentUserID = user?.id

    if (type === "addMember") {
        return (
            <Modal
                open={visible}
                onClose={() => handleClose(false)}
                sx={{ zIndex: 999 }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: { xs: "90%", md: 500 } }}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                        <div className=' font-20 font-bold greyColor'>{"Add Member"}</div>
                        <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross />} onClick={() => handleClose(false)} />
                    </Grid>
                    <div className="searchInputHeaderBox mb-3" style={{ width: '100%' }}>
                        <input className="searchInput" placeholder="Search..." onChange={(e) => _getProfile(e.target.value, committed_to_organization)} />
                        <div className="saerchIconHeaderDiv">
                            <SearchGrey />
                        </div>
                    </div>
                    <Grid container>
                        <div className="whocanviewRole mb-2" onClick={handleClick}>
                            Filter by role
                            <Down className=" ml-2 downBlack" />
                        </div>
                        <Popover
                            id={_id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={_handleClose}
                            classes={{ paper: "dropdown-paper" }}
                            sx={{ width: 250, zIndex: 99999999 }}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                        >
                            <div>
                                <div className="checkboxDiv mb-3">
                                    <Checkbox
                                        checked={is_player_view}
                                        onClick={() =>
                                            handleChange("is_player_view", !is_player_view)
                                        }
                                        className="checkbox1"
                                        style={{
                                            marginTop: -3
                                        }}
                                        sx={{
                                            color: "rgba(201, 208, 216, 1)",
                                            "&.Mui-checked": {
                                                color: COLORS.primary
                                            }
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        className="text_primary06 checkboxLabel"
                                    >
                                        Player
                                    </Typography>
                                </div>
                                <div className="checkboxDiv mb-3">
                                    <Checkbox
                                        checked={is_parent_view}
                                        onClick={() =>
                                            handleChange("is_parent_view", !is_parent_view)
                                        }
                                        className="checkbox1"
                                        style={{
                                            marginTop: -3
                                        }}
                                        sx={{
                                            color: "rgba(201, 208, 216, 1)",
                                            "&.Mui-checked": {
                                                color: COLORS.primary
                                            }
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        className="text_primary06 checkboxLabel"
                                    >
                                        Community
                                    </Typography>
                                </div>
                                <div className="checkboxDiv mb-3">
                                    <Checkbox
                                        checked={is_coach_view}
                                        onClick={() => handleChange("is_coach_view", !is_coach_view)}
                                        className="checkbox1"
                                        style={{
                                            marginTop: -3
                                        }}
                                        sx={{
                                            color: "rgba(201, 208, 216, 1)",
                                            "&.Mui-checked": {
                                                color: COLORS.primary
                                            }
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        className="text_primary06 checkboxLabel"
                                    >
                                        Coach
                                    </Typography>
                                </div>
                                <div className="checkboxDiv mb-3">
                                    <Checkbox
                                        checked={is_organization_view}
                                        onClick={() =>
                                            handleChange("is_organization_view", !is_organization_view)
                                        }
                                        className="checkbox1"
                                        style={{
                                            marginTop: -3
                                        }}
                                        sx={{
                                            color: "rgba(201, 208, 216, 1)",
                                            "&.Mui-checked": {
                                                color: COLORS.primary
                                            }
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        className="text_primary06 checkboxLabel"
                                    >
                                        Organization
                                    </Typography>
                                </div>
                            </div>
                        </Popover>
                        <Autocomplete
                            freeSolo
                            style={{ zIndex: 9999999, maxWidth: "60%" }}
                            fullWidth
                            size="small"
                            multiple
                            limitTags={2}
                            id="combo-box-demo"
                            options={userList}
                            onInputChange={onInputChange}
                            // inputValue={committed_to_organization_custom}
                            getOptionLabel={option => {
                                return option.title
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    placeholder='Filter team'
                                    variant="outlined"
                                    size="small"
                                    style={{ borderRadius: 10 }}
                                />
                            )}
                            sx={{
                                "& legend": { display: "none" },
                                "& .MuiInputLabel-shrink": {
                                    opacity: 0,
                                    transition: "all 0.2s ease-in"
                                },
                                "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: "#D9D9D9",
                                    borderWidth: 1
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: "#D9D9D9"
                                },
                                "& fieldset": {
                                    borderRadius: 2,
                                    // maxHeight: "40px",
                                    zIndex: 9999999999,
                                    marginTop: "5px"
                                }
                            }}
                            onChange={(e, newValue) => {
                                handleChange("committed_to_organization", newValue)

                                _getProfile(searchText, newValue)
                            }}
                            name={"committed_to_organization"}
                            value={committed_to_organization}
                        />
                    </Grid>
                    {
                        loadingUsers && <div className='divCenter'><CircularProgress size={14} /></div>
                    }
                    <Grid style={{ maxHeight: '70%', overflow: "auto" }}>
                        {
                            Array.isArray(filteredList) && removeExistingUsers(filteredList)?.map((user, index) => (
                                <Grid key={index} className="followerListGrid" container alignItems={'center'} justifyContent={"space-between"}>
                                    <Grid container alignItems={"center"} item xs={8} onClick={() => handleUserClick(user)}>
                                        <img src={printName(user)?.ProfileDP} className="followerProfile" />
                                        <div>{printName(user)?.ProfileName}</div>
                                    </Grid>
                                    {
                                        currentUserID !== user?.id &&
                                        <Checkbox
                                            disabled={false}
                                            checked={members?.find(id => id === user?.id)}
                                            onClick={() => {
                                                if (members?.find(id => id === user?.id)) {
                                                    const removed = members?.filter(id => id !== user?.id)
                                                    handleChange("members", removed)
                                                } else {
                                                    handleChange("members", [...members, user?.id])
                                                }
                                            }}
                                            // className="checkbox1"
                                            style={{
                                                marginTop: -3
                                            }}
                                            sx={{
                                                color: "rgba(201, 208, 216, 1)",
                                                "&.Mui-checked": {
                                                    color: COLORS.primary
                                                }
                                            }}
                                        />
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid container justifyContent={'flex-end'}>
                        <AppButton
                            loading={loadingChat}
                            onClick={_addMemberChannel}
                            disabled={members?.length === 0}
                            title={'Add Member'}
                            backgroundColor={COLORS.primary} color={COLORS.white}
                            width={130} fontWeight={300} className={'mt-4'} height={35} borderRadius={15} />
                    </Grid>
                </Box>
            </Modal>
        )
    }

    if (type === "changeName") {
        return (
            <Modal
                open={visible}
                onClose={() => handleClose(false)}
                sx={{ zIndex: 9999999 }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: { xs: "90%", md: 500 }, height: { xs: 300, md: 250 } }}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                        <Grid sx={{ width: '80%' }} className='font-20 font-bold greyColor'>{"Changing the name of a group chat changes it for everyone."}</Grid>
                        <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross />} onClick={() => handleClose(false)} />
                    </Grid>
                    <AppInput placeholder={'Group Name'} height={40} className={'mb-4'} value={group_name} name={'group_name'} onChange={handleChange} />
                    <Grid container justifyContent={'flex-end'}>
                        <AppButton
                            loading={loadingChat}
                            onClick={_updateChannel}
                            disabled={!group_name}
                            title={'Save'}
                            backgroundColor={COLORS.primary} color={COLORS.white}
                            fontWeight={300} className={'mt-2'} height={35} borderRadius={15} />
                    </Grid>
                </Box>
            </Modal>
        )
    }
    return (
        <Modal
            open={visible}
            onClose={() => handleClose(false)}
            sx={{ zIndex: 9999999 }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...style, width: { xs: "90%", md: 500 } }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                    <div className='font-20 font-bold greyColor'>{"New Group"}</div>
                    <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross />} onClick={() => handleClose(false)} />
                </Grid>
                <AppInput placeholder={'Group Name'} height={40} className={'mb-2'} label={'Group Name'} value={group_name} name={'group_name'} onChange={handleChange} />
                <div className='font-16 mb-2'>Add Members</div>
                <div className="searchInputHeaderBox mb-3" style={{ width: '100%' }}>
                    <input className="searchInput" placeholder="Search..." onChange={(e) => _getProfile(e.target.value, committed_to_organization)} />
                    <div className="saerchIconHeaderDiv">
                        <SearchGrey />
                    </div>
                </div>
                <Grid container>
                    <div className="whocanviewRole mb-2" onClick={handleClick}>
                        Filter by role
                        <Down className=" ml-2 downBlack" />
                    </div>
                    <Popover
                        id={_id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={_handleClose}
                        classes={{ paper: "dropdown-paper" }}
                        sx={{ width: 250, zIndex: 99999999 }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        <div>
                            <div className="checkboxDiv mb-3">
                                <Checkbox
                                    checked={is_player_view}
                                    onClick={() =>
                                        handleChange("is_player_view", !is_player_view)
                                    }
                                    className="checkbox1"
                                    style={{
                                        marginTop: -3
                                    }}
                                    sx={{
                                        color: "rgba(201, 208, 216, 1)",
                                        "&.Mui-checked": {
                                            color: COLORS.primary
                                        }
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    className="text_primary06 checkboxLabel"
                                >
                                    Player
                                </Typography>
                            </div>
                            <div className="checkboxDiv mb-3">
                                <Checkbox
                                    checked={is_parent_view}
                                    onClick={() =>
                                        handleChange("is_parent_view", !is_parent_view)
                                    }
                                    className="checkbox1"
                                    style={{
                                        marginTop: -3
                                    }}
                                    sx={{
                                        color: "rgba(201, 208, 216, 1)",
                                        "&.Mui-checked": {
                                            color: COLORS.primary
                                        }
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    className="text_primary06 checkboxLabel"
                                >
                                    Community
                                </Typography>
                            </div>
                            <div className="checkboxDiv mb-3">
                                <Checkbox
                                    checked={is_coach_view}
                                    onClick={() => handleChange("is_coach_view", !is_coach_view)}
                                    className="checkbox1"
                                    style={{
                                        marginTop: -3
                                    }}
                                    sx={{
                                        color: "rgba(201, 208, 216, 1)",
                                        "&.Mui-checked": {
                                            color: COLORS.primary
                                        }
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    className="text_primary06 checkboxLabel"
                                >
                                    Coach
                                </Typography>
                            </div>
                            <div className="checkboxDiv mb-3">
                                <Checkbox
                                    checked={is_organization_view}
                                    onClick={() =>
                                        handleChange("is_organization_view", !is_organization_view)
                                    }
                                    className="checkbox1"
                                    style={{
                                        marginTop: -3
                                    }}
                                    sx={{
                                        color: "rgba(201, 208, 216, 1)",
                                        "&.Mui-checked": {
                                            color: COLORS.primary
                                        }
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    className="text_primary06 checkboxLabel"
                                >
                                    Organization
                                </Typography>
                            </div>
                        </div>
                    </Popover>
                    <Autocomplete
                        freeSolo
                        style={{ zIndex: 9999999, maxWidth: "60%" }}
                        fullWidth
                        size="small"
                        multiple
                        limitTags={2}
                        id="combo-box-demo"
                        options={userList}
                        onInputChange={onInputChange}
                        // inputValue={committed_to_organization_custom}
                        getOptionLabel={option => {
                            return option.title
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                placeholder='Filter team'
                                variant="outlined"
                                size="small"
                                style={{ borderRadius: 10 }}
                            />
                        )}
                        sx={{
                            "& legend": { display: "none" },
                            "& .MuiInputLabel-shrink": {
                                opacity: 0,
                                transition: "all 0.2s ease-in"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: "#D9D9D9",
                                borderWidth: 1
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: "#D9D9D9"
                            },
                            "& fieldset": {
                                borderRadius: 2,
                                // maxHeight: "40px",
                                zIndex: 9999999999,
                                marginTop: "5px"
                            }
                        }}
                        onChange={(e, newValue) => {
                            handleChange("committed_to_organization", newValue)
                            _getProfile(searchText, newValue)
                        }}
                        name={"committed_to_organization"}
                        value={committed_to_organization}
                    />
                </Grid>
                {
                    loadingUsers && <div className='divCenter'><CircularProgress size={14} /></div>
                }
                <Grid className='messageUserListDiv' sx={{ maxHeight: { xs: "39%", md: "39%" } }}>
                    {
                        Array.isArray(filteredList) && filteredList?.map((user, index) => (
                            <Grid key={index} className="followerListGrid" container alignItems={'center'} justifyContent={"space-between"}>
                                <Grid container alignItems={"center"} item xs={8} onClick={() => handleUserClick(user)}>
                                    <img src={printName(user)?.ProfileDP} className="followerProfile" />
                                    <div>{printName(user)?.ProfileName}</div>
                                </Grid>
                                {
                                    currentUserID !== user?.id?.toString() &&
                                    <Checkbox
                                        disabled={false}
                                        checked={users?.some(id => id?.toString() === user?.id?.toString())}
                                        onClick={() => {
                                            if (users?.find(id => id?.toString() === user?.id?.toString())) {
                                                const removed = users?.filter(id => id?.toString() !== user?.id?.toString())
                                                handleChange("users", removed)
                                            } else {
                                                handleChange("users", [...users, user?.id?.toString()])
                                            }
                                        }}
                                        // className="checkbox1"
                                        style={{
                                            marginTop: -3
                                        }}
                                        sx={{
                                            color: "rgba(201, 208, 216, 1)",
                                            "&.Mui-checked": {
                                                color: COLORS.primary
                                            }
                                        }}
                                    />
                                }
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid container justifyContent={'flex-end'} className='mt-2'>
                    <AppButton
                        loading={loadingChat}
                        onClick={_createChannel}
                        disabled={!group_name || users?.length < 1}
                        title={'Create Group'}
                        backgroundColor={COLORS.primary} color={COLORS.white}
                        width={120} fontWeight={300} className={'mt-2'} height={35} borderRadius={15} />
                </Grid>
            </Box>
        </Modal>
    )
}

export default MessageModal