import React, { useContext, useEffect, useRef } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  List,
  Popover,
  IconButton,
  Drawer,
  Box,
  Grid,
  ListItemText,
  ListItemButton,
  ListItem,
  Divider
} from "@mui/material"
import { ReactComponent as BellIcon } from "../../assets/svg/bell.svg"
import { ReactComponent as Down } from "../../assets/svg/down.svg"
import { ReactComponent as LocationIcon } from "../../assets/svg/location_on.svg"
import { ReactComponent as HomeIcon } from "../../assets/svg/home.svg"
import { ReactComponent as CalendarIcon } from "../../assets/svg/calendar.svg"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"
import { ReactComponent as ProfileIcon } from "../../assets/svg/profile.svg"
import { ReactComponent as SettingsIcon } from "../../assets/svg/settings.svg"
import { ReactComponent as LogoutIcon } from "../../assets/svg/logout.svg"
import { ReactComponent as PlusIcon } from "../../assets/svg/plus.svg"
import { ReactComponent as SettingIcon } from "../../assets/svg/setting.svg"
import { ReactComponent as Team } from "../../assets/svg/Team.svg"
import { ReactComponent as TeamFill } from "../../assets/svg/TeamFill.svg"
import { ReactComponent as HeartIcon } from "../../assets/svg/heart.svg"
import { ReactComponent as PotentialIcon } from "../../assets/svg/potential.svg"
import { ReactComponent as LogoutOrange } from "../../assets/svg/logoutOrange.svg"
import { ReactComponent as MessagesIcon } from "../../assets/svg/messages.svg"
import LinkIcon from "@mui/icons-material/Link"

import "rsuite/dist/rsuite.min.css"
import AppContext from "../../Context"
import AppButton from "../AppButton"
import MenuIcon from "@mui/icons-material/Menu"
import { COLORS } from "../../constants"
import { searchUserProfile_V2 } from "../../api/auth"
import GeneralModal from "../GeneralModal"
import { POTENTIALPLAYERS } from "../../constants/routes"
import moment from "moment"
import AvatarIcon from "../../assets/images/avatar.png"
import NavLogo from "../../assets/images/Nav-logo.png"
import LoginLogo from "../../assets/images/Login-Logo.png"

export default function HomeHeader({
  PlayerProfile,
  admin,
  loginPress,
  data,
  dashboard
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorEl1, setAnchorEl1] = React.useState(null)
  let userData = localStorage.getItem("userData")
  const {
    ProfileName,
    isCoach,
    ProfileDP,
    handleOpenPost,
    isParent,
    user,
    resetState,
    isPlayer,
    isOrganization,
    organizationData,
    notifications,
    _getNotifications,
    notificationsCount,
    messageUnreadCount,
    setHashtagsText,
    hashtagsText
  } = useContext(AppContext)
  const [state, setState] = React.useState({
    openDrawer: false,
    logoutVisible: false,
    searchText: "",
    userList: []
  })
  const { openDrawer, logoutVisible, searchText, userList } = state
  userData = JSON.parse(userData)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick1 = event => {
    _getNotifications()
    setAnchorEl1(event.currentTarget)
  }
  const handleClose1 = () => {
    setAnchorEl1(null)
  }
  const handleCloseLogout = () => {
    handleChange("logoutVisible", false)
  }

  const toggleDrawer = open => {
    handleChange("openDrawer", open)
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    resetState()
    handleCloseLogout()
    if (window.location.host === "app.thesportsportal.com") {
      window.location.href = "https://thesportsportal.com/"
    } else {
      navigate("/")
    }
  }

  useEffect(() => {
    const handleScroll = event => {
      setAnchorEl(null)
      setAnchorEl1(null)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const getClass = route => {
    return location.pathname === route ? "breadcumbActive" : "breadcumb"
  }

  const getClassWithNested = route => {
    return location.pathname.includes(route) ? "breadcumbActive" : "breadcumb"
  }

  let controller = useRef();
  const _getProfile = async searchText => {
    // Abort the previous request if it exists
    if (controller.current) {
      controller.current.abort();
    }

    // Create a new AbortController for the current request
    controller.current = new AbortController();
    const signal = controller.current?.signal;
    try {
      handleChange("searchText", searchText)
      const token = localStorage.getItem("token")
      if (searchText) {
        const res = await searchUserProfile_V2(`?search=${searchText}`, token, signal)
        handleChange("userList", res?.data?.results)
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }


  const clickNotifcation = (item) => {
    if (item?.obj_type === "user" || item?.obj_type === "playerparentlink_id") {
      handleUserClick(item?.sending_user)
    }
    if (item?.obj_type === "post") {
      navigate(`/post/${item?.obj_id}`)
    }
  }

  const printName = user => {
    const isPlayer = user?.role === "Player"
    const isParent = user?.role === "Parent"
    const isCoach = user?.role === "Coach"
    const isOrganization = user?.role === "Organization"
    const fullName = user?.first_name + " " + user?.last_name
    const ProfileDP = isPlayer
      ? user?.player?.picture
      : isParent
        ? user?.parent?.picture
        : isOrganization
          ? user?.organization?.logo
          : isCoach
            ? user?.coach?.picture
            : user?.player?.picture || AvatarIcon
    const ProfileName = isOrganization
      ? user?.organization?.organization_name
      : user?.name || "Guest"
    return { ProfileName, ProfileDP }
  }

  const handleUserClick = user => {
    const role = user?.role
    const route =
      role === "Player"
        ? `/player-profile/${user?.id}`
        : role === "Organization"
          ? `/organization-profile/${user?.id}`
          : role === "Coach"
            ? `/coach-profile/${user?.id}`
            : `/parent-profile/${user?.id}`
    handleChange("searchText", "")
    navigate(route)
  }

  const open = Boolean(anchorEl)
  const open1 = Boolean(anchorEl1)
  const id = open ? "simple-popover" : undefined
  const id1 = open1 ? "simple-popover1" : undefined
  const currentUser = user
  return (
    <div className={currentUser && "mainHeader"}>
      <header
        className={`login_nav  ${currentUser
          ? PlayerProfile
            ? "home_nav_player"
            : "home_nav_player"
          : "home_nav"
          }`}
      >
        <div className={`container`}>
          <div className="row justify-content-between mt-2 mb-2">
            <Link to={currentUser ? "/dashboard" : "/"}>
              <img
                src={currentUser ? NavLogo : LoginLogo}
                className="applogo"
              />
            </Link>

            <ul className="displayMobileHide row align-items-center">
              {currentUser ? (
                <>
                  <>
                    <li>
                      <Link
                        to={"/location-access?map=true"}
                        className="listpool mr-4 c-pointer"
                      >
                        <LocationIcon />
                      </Link>
                    </li>
                    <li onClick={handleClick1}>
                      <div className="listpool mr-4 c-pointer">
                        {!!notificationsCount && (
                          <div className="messageUnreadCount">
                            {notificationsCount}
                          </div>
                        )}
                        <BellIcon />
                      </div>
                    </li>
                    <li>
                      <Link
                        to={"/messages"}
                        className="listpool mr-4 c-pointer"
                      >
                        {!!messageUnreadCount && (
                          <div className="messageUnreadCount">
                            {messageUnreadCount}
                          </div>
                        )}
                        <MessagesIcon />
                      </Link>
                    </li>
                    <li onClick={handleClick}>
                      <div className="listpool text-white mr-4">
                        <img
                          src={ProfileDP}
                          className={"c-pointer userprofile"}
                        />
                        {ProfileName}
                        <Down className="ml-2" />
                      </div>
                    </li>
                  </>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    classes={{ paper: "dropdown-paper" }}
                    sx={{ width: 600 }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                  >
                    <div className="notificationPopup">
                      <div className="rowBetween">
                        <div className="font-bold font-18">Notifications</div>
                        <div
                          className="viewall"
                          onClick={() => navigate("/notifications")}
                        >
                          View all
                        </div>
                      </div>
                      <Divider className="mt-3" />
                      {Array.isArray(notifications) &&
                        notifications?.length === 0 && (
                          <div className="noNotification">No Notification</div>
                        )}
                      {Array.isArray(notifications) &&
                        notifications?.map((item, index) => (
                          <div onClick={() => clickNotifcation(item)} className="notificationItem" key={index}>
                            <img
                              src={printName(item?.sending_user).ProfileDP}
                              className={"c-pointer userprofile mr-4"}
                            />
                            <div className="row">
                              <div>{item?.context}</div>
                              <div className="d-flex ml-3">
                                <span className="graydot" />
                                <span className="text-gray">
                                  {moment(item?.timestamp).fromNow()}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div style={{ height: 20 }} />
                    </div>
                  </Popover>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    classes={{ paper: "dropdown-paper" }}
                    sx={{ width: 250 }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                  >
                    <div>
                      <AppButton
                        disabled={data?.length === 0 ? true : false}
                        title={
                          <div className="dropdownItem">
                            <ProfileIcon />
                            <div className="ml-2">Profile</div>
                          </div>
                        }
                        backgroundColor={"#fff"}
                        onClick={() =>
                          navigate(
                            isPlayer
                              ? `/player-profile/${user?.id}`
                              : isOrganization
                                ? `/organization-profile/${organizationData?.user?.id}`
                                : isCoach
                                  ? `/coach-profile/${user?.id}`
                                  : `/parent-profile/${user?.id}`
                          )
                        }
                        color={"#000"}
                        width={200}
                      />
                      <AppButton
                        title={
                          <div className="dropdownItem">
                            <SettingsIcon />
                            <div className="ml-2">Settings</div>
                          </div>
                        }
                        onClick={() => navigate("/settings")}
                        backgroundColor={"#fff"}
                        width={200}
                        color={"#000"}
                      />
                      <AppButton
                        width={200}
                        title={
                          <div className="dropdownItem">
                            <LogoutIcon />
                            <div className="ml-2">Log Out</div>
                          </div>
                        }
                        className={"text-left"}
                        onClick={() => handleChange("logoutVisible", true)}
                        backgroundColor={"#fff"}
                        color={"#000"}
                      />
                    </div>
                  </Popover>
                </>
              ) : (
                <>
                  <li>
                    <Link to={"/about-us"} className="listpool">
                      {"About Us"}
                    </Link>
                    <Link to={"/contact-us"} className="listpool">
                      {"Contact Us"}
                    </Link>
                  </li>
                  <li>
                    <Link to={"/faq"} className="listpool">
                      {"FAQ"}
                    </Link>
                  </li>
                  <AppButton
                    onClick={() =>
                      loginPress ? loginPress() : navigate("/login")
                    }
                    outlined
                    color={"#000000D9"}
                    borderColor={"#D9D9D9"}
                    className={"mr-3"}
                    height={40}
                    width={80}
                    title={"Log in"}
                  />
                  <AppButton
                    onClick={() => navigate("/signup")}
                    backgroundColor={COLORS.primary}
                    color={COLORS.white}
                    height={40}
                    width={100}
                    title={"Register"}
                  />
                </>
              )}
            </ul>
            <ul className="hideWeb displayFlexMobile d-flex align-items-center">
              {currentUser && !admin ? (
                <div className="displayFlexMobile">
                  {/* <li>
                    <Link to={"/rvs"}>
                      <div className="listpool menuBox mr-4">List my RV</div>
                    </Link>
                  </li> */}
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => toggleDrawer(true)}
                    edge="start"
                    sx={{ mr: 1, ...(openDrawer && { display: "none" }) }}
                  >
                    <MenuIcon className="MenuIcon" />
                  </IconButton>
                </div>
              ) : (
                <div className="displayFlexMobile">
                  <AppButton
                    onClick={() => navigate("/login")}
                    outlined
                    color={"#000000D9"}
                    borderColor={"#D9D9D9"}
                    className={"mr-3"}
                    height={40}
                    width={80}
                    title={"Log in"}
                  />
                  <AppButton
                    onClick={() => navigate("/signup")}
                    backgroundColor={COLORS.primary}
                    color={COLORS.white}
                    height={40}
                    width={100}
                    title={"Register"}
                  />
                </div>
              )}
            </ul>
            <Drawer
              anchor={"left"}
              open={openDrawer}
              onClose={() => toggleDrawer(false)}
            >
              <Box
                className="home_nav_player_mobile"
                sx={{
                  width: 250
                }}
                role="presentation"
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => navigate("/location-access?map=true")}
                    >
                      <ListItemText
                        primary={
                          <span className="text-white">
                            <LocationIcon /> Location
                          </span>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/dashboard")}>
                      <ListItemText
                        primary={
                          <span className="text-white">
                            <BellIcon /> Notification
                          </span>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/messages")}>
                      <ListItemText
                        primary={
                          <span className="text-white">
                            {!!messageUnreadCount && (
                              <div className="messageUnreadCount">
                                {messageUnreadCount}
                              </div>
                            )}
                            <MessagesIcon /> Messages
                          </span>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        navigate(
                          isPlayer
                            ? `/player-profile/${user?.id}`
                            : isOrganization
                              ? `/organization-profile/${organizationData?.user?.id}`
                              : isCoach
                                ? `/coach-profile/${user?.id}`
                                : `/parent-profile/${user?.id}`
                        )
                      }
                    >
                      <ListItemText
                        primary={
                          <span className="text-white">
                            <ProfileIcon /> Profile
                          </span>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/settings")}>
                      <ListItemText
                        primary={
                          <span className="text-white">
                            <SettingsIcon /> Settings
                          </span>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => handleChange("logoutVisible", true)}
                    >
                      <ListItemText
                        primary={
                          <span className="text-white">
                            <LogoutIcon className="MenuIcon" /> Log Out
                          </span>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => navigate("/dashboard/bookings")}
                      >
                        <ListItemText primary={"Dashboard"} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate("/dashboard/trips")}>
                        <ListItemText primary={"My Trips"} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate("/dashboard/favorites")}>
                        <ListItemText primary={"My Favorites"} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => navigate("/dashboard/inbox")}
                      >
                        <ListItemText primary={"Messages"} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate("/settings")}>
                        <ListItemText primary={"Settings"} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={logout}>
                        <ListItemText primary={"Logout"} />
                      </ListItemButton>
                    </ListItem> */}
                </List>
              </Box>
            </Drawer>
          </div>
        </div>
      </header>
      {currentUser && (
        <div className="headerBottom">
          <div className={`container`}>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={12} md={8} container>
                <div
                  className={getClass("/dashboard")}
                  onClick={() => navigate("/dashboard")}
                >
                  <HomeIcon className="mr-2" />
                  <div>Home</div>
                </div>
                <div
                  className={getClassWithNested("/calendar")}
                  onClick={() => navigate("/calendar")}
                >
                  <CalendarIcon className="mr-2" />
                  <div>Calendar</div>
                </div>
                {/* {isCoach && ( */}
                <div onClick={handleOpenPost} className="breadcumb">
                  <PlusIcon className="mr-2" />
                  <div>Create a post</div>
                </div>
                {/* )} */}
                {isPlayer && (
                  <>
                    <div
                      onClick={() => navigate("/favorites")}
                      className={getClassWithNested("/favorites")}
                    >
                      <HeartIcon className="mr-2" />
                      <div>Favorites</div>
                    </div>
                  </>
                )}
                {isParent && (
                  <>
                    <div
                      onClick={() => navigate(`/linked-player/${user?.id}`)}
                      className={getClassWithNested("/favorites")}
                    >
                      <LinkIcon className="mr-2" style={{ fontSize: "20px" }} />
                      <div>Linked Player</div>
                    </div>
                  </>
                )}
                {(isOrganization || isParent || isCoach) && (
                  <>
                    <div
                      onClick={() => navigate("/favorites")}
                      className={getClassWithNested("/favorites")}
                    >
                      <HeartIcon className="mr-2" />
                      <div>Favorites</div>
                    </div>
                    {(isOrganization || isCoach) && (
                      <div
                        onClick={() => navigate("/teams")}
                        className={getClassWithNested("/teams")}
                      >
                        {getClassWithNested("/teams") ? (
                          <TeamFill className="mr-2" />
                        ) : (
                          <Team className="mr-2" />
                        )}
                        <div>Teams</div>
                      </div>
                    )}
                  </>
                )}
                {isOrganization && organizationData && (
                  <>
                    <div
                      onClick={() =>
                        navigate(
                          POTENTIALPLAYERS?.replace(
                            ":organization",
                            organizationData?.id
                          )?.replace(":user", organizationData?.user?.id)
                        )
                      }
                      className={getClassWithNested(POTENTIALPLAYERS)}
                    >
                      <PotentialIcon className="mr-2" />
                      <div>Potential Players</div>
                    </div>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                container
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <div className="searchInputHeaderBox">
                  <input
                    className="searchInput"
                    placeholder="Search..."
                    value={searchText}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        navigate(`/search?text=${searchText}`)
                      }
                    }}
                    onChange={e => {
                      if (e.target.value?.startsWith("#")) {
                        handleChange("searchText", e.target.value)
                        setHashtagsText(e.target.value)
                      } else {
                        setHashtagsText("")
                        _getProfile(e.target.value)
                      }
                    }}
                  />
                  <div
                    onClick={() =>
                      searchText !== ""
                        ? navigate(`/search?text=${searchText}`)
                        : console.log()
                    }
                    className="saerchIconHeaderDiv"
                  >
                    <SearchGrey />
                  </div>
                </div>
                <SettingIcon
                  onClick={() => navigate(`/search?text=${searchText}`)}
                  className="ml-2 cursor"
                />
                {!hashtagsText && searchText !== "" && userList?.length > 0 && (
                  <div className="searchBox">
                    {userList?.map((user, index) => (
                      <div
                        onClick={() => handleUserClick(user)}
                        className="saerchList"
                        key={index}
                      >
                        {user?.name ||
                          (user?.first_name ? (user?.first_name + " " + user?.last_name) :
                            (user?.organization?.organization_name)) ||
                          ""}
                      </div>
                    ))}
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      <GeneralModal
        visible={logoutVisible}
        icon={<LogoutOrange />}
        handlePress={logout}
        handleClose={handleCloseLogout}
        ActionButtonText={"Yes"}
        title={"Log Out"}
        description={"Are you sure you want to Log Out?"}
      />
    </div>
  )
}
