import React, { useState, useEffect, useMemo, useContext } from "react"
import { MentionsInput, Mention } from "react-mentions"
import axios from "axios"
import { debounce } from "lodash"
import AppContext from "../../Context"
export default function MentionTextBox({ data, setData, border, setURL }) {
  const { user } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const res = localStorage.getItem("allUsers")
  const allUsers = res && JSON.parse(res)
  const styles = {
    // input: {
    //   color: "#000",
    //   height: "40px",
    //   marginBottom: "50px",
    //   outline: "none",
    //   width: "100%",
    //   borderRadius: "5px",
    //   border: border,
    //   lineHeight: 1.3
    // },
    mentionStyle: {
      backgroundColor: "#000",
      color: "blue"
    },
    input: {
      backgroundColor: "#fff",
      color: "#000",
      height: "38px",
      marginBottom: "50px",
      outline: "none",
      width: "100%",
      borderRadius: "5px",
      border: "none",
      lineHeight: 1.3,
      paddingLeft: "8px"
    },
    suggestions: {
      list: {
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
        maxHeight: "200px",
        overflowY: "auto"
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#cee4e5"
        }
      }
    }
  }
  const [comment, setComment] = useState("")
  const [mentions, setMentions] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  // Function to validate URL
  // Function to check if a string is a valid URL
  const isValidUrl = str => {
    // Regular expression to match URLs
    const urlPattern = /(https?:\/\/[^\s]+)/g
    // Test if the string contains a URL
    return urlPattern.test(str)
  }

  // Function to extract URLs from a string
  const extractUrls = str => {
    // Regular expression to match URLs
    const urlPattern = /(https?:\/\/[^\s]+)/g
    // Extract URLs from the string and return them as an array
    return str.match(urlPattern) || []
  }
  const fetchUsers = useMemo(
    () =>
      debounce(async search => {
        try {
          setLoading(true) // Set loading to true when data fetching starts
          // const url = `https://amateur-sports-insi-42-staging.botics.co/api/v1/users/?limit=1000&offset=20`
          // const config = { headers: { Authorization: `token ${token}` } }
          // const response = await axios.get(url, config)
          const mappedUsers = allUsers
            ?.filter(item => item?.id !== user.id) // Filter out items where id is not equal to user.id
            .map(item => {
              // Extract username from parent, coach, or organization
              return {
                username: item?.name,
                id: String(item?.id),
                display: String(item?.username)
              }
            })
          setLoading(false)
          setUsers(mappedUsers)
        } catch (error) {
          console.error(error)
          setLoading(false)
        } finally {
          setLoading(false) // Set loading to false regardless of success or error
        }
      }, 300),
    []
  )
  useEffect(() => {
    fetchUsers()
  }, [])

  const handleMentionOnChange = e => {
    const inputValue = e.target.value
    // Set the cleaned value and the user ID separately
    setData(inputValue)
    // Check if input value is a valid URL
    if (isValidUrl(inputValue)) {
      // Extract URLs from the input value
      const urls = extractUrls(inputValue)
      // Set the first URL found in the state (you may adjust this logic based on your requirements)
      setURL(urls[0])
    } else {
      // If input value is not a valid URL, clear the URL state
      setURL("")
    }

    // Trigger fetching users when "@" is typed
    // const lastWord = cleanedValue.split(" ").pop()
    // if (lastWord.startsWith("@")) {
    //   const searchQuery = lastWord.substring(1)
    //   fetchUsers(searchQuery)
    // }
  }

  const handlePaste = e => {
    const pastedText = e.clipboardData.getData("text")
    setData(pastedText) // Paste the entire text without processing
  }
  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>Loading...</div>
      ) : (
        <MentionsInput
          onPaste={handlePaste}
          value={data}
          onChange={handleMentionOnChange}
          placeholder={"Type here... mention people using '@'"}
          a11ySuggestionsListLabel={"Add comment"}
          style={styles}
        >
          <Mention
            markup="@[__display__](__id__)"
            appendSpaceOnAdd
            data={search => {
              const filteredUsers = users.filter(
                user =>
                  (user.username &&
                    user.username
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (user.display &&
                    user.display.toLowerCase().includes(search.toLowerCase()))
              )
              return filteredUsers
            }}
            trigger="@"
            style={{ backgroundColor: "#d1c4e9", height: "35px" }}
            renderSuggestion={user => (
              <div>{`${user?.display || "No Username"} (${user?.username || "No name"})`}</div>
            )}
          />
        </MentionsInput>
      )}
    </>
  )
}
