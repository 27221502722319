import React, { useContext, useRef, useState } from "react"
import { AppButton, AppInput, Label } from ".."
import { Box, Checkbox, Grid, Modal, Popover, Typography } from "@mui/material"
import { COLORS, MAP_API_KEY } from "../../constants"
import AppContext from "../../Context"
import { organizationReview, postCreate } from "../../api/auth"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import { ReactComponent as Down } from "../../assets/svg/downBlack.svg"
import { ReactComponent as AddImage } from "../../assets/svg/add_photo_alternate.svg"
import { ReactComponent as AddVideo } from "../../assets/svg/video_library.svg"
import AutoComplete from "react-google-autocomplete"
import MentionTextBox from "../Mention/Mention"
import Microlink from "@microlink/react"
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CropDinIcon from '@mui/icons-material/CropDin';
import Crop75Icon from '@mui/icons-material/Crop75';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';

function AddPost({ }) {
  const {
    ProfileName,
    ProfileDP,
    addPost,
    setFetchPost,
    handleClosePost,
    user
  } = useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const imageRef = useRef()
  const style = {
    position: "absolute",
    top: "50%",
    minWidth: 350,
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    height: "90%",
    overflow: "auto",
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl1, setAnchorEl1] = useState(null)
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(0)

  const [url, setURL] = useState("")
  const [state, setState] = useState({
    selectedTag: [],
    loading: false,
    is_player_view: true,
    is_parent_view: true,
    is_coach_view: true,
    is_organization_view: true,
    location: "",
    images: null,
    imagesObj: null,
    video_file: null,
    video_fileObj: null,
    text: "",
    url_field: "",
    comments_allowed: true,
  })
  const {
    selectedTag,
    is_player_view,
    is_parent_view,
    is_coach_view,
    is_organization_view,
    location,
    images,
    imagesObj,
    video_file,
    video_fileObj,
    text,
    url_field,
    comments_allowed,
    loading
  } = state

  const width = 400

  const handleClickImage = () => {
    imageRef.current.click()
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick1 = event => {
    setAnchorEl1(event.currentTarget)
  }
  const handleClose1 = () => {
    setAnchorEl1(null)
  }

  const handleChange = (key, value) => {
    if (key === "url_field") {
      if (value && !/^https:\/\//i.test(value)) {
        value = "https://" + value
      }
    }
    setState(prevState => ({ ...prevState, [key]: value }))
  }
  const _postCreate = async () => {
    try {
      handleChange("loading", true)
      const token = localStorage.getItem("token")
      const payload = new FormData()
      const allhashtags = data?.match(/#[a-z]+/gi) || []

      const mentionsInText = data?.match(/\(([^)]+)\)/g) || []
      const regex = /\[@(.+?)\s\(.+?\)\]/g || []
      let tagged_users = mentionsInText?.map(mention =>
        parseInt(mention?.slice(1, -1), 10)
      )
      let comment = data && data?.replace(/@\[.*?\]\(\d+\)/g, match => {
        // Extract the username from the match
        const usernameMatch = match?.match(/@\[(.*?)\]/)
        if (usernameMatch && usernameMatch[1]) {
          // Remove spaces between the username
          const cleanedUsername = usernameMatch?.[1]?.replace(/\s+/g, "")
          return `@${cleanedUsername}`
        }
        return match // Return the original match if no username is found
      })
      payload.append("user", user?.id)
      payload.append("is_player_view", is_player_view)
      payload.append("is_parent_view", is_parent_view)
      payload.append("is_coach_view", is_coach_view)
      payload.append("is_organization_view", is_organization_view)
      payload.append("location", location)
      payload.append("url_field", url_field)
      payload.append("comments_allowed", comments_allowed)
      tagged_users?.forEach(userId => {
        payload.append("tagged_users", userId)
      })
      payload.append("aspect_ratio", selectedAspectRatio?.toString())
      payload.append("hashtags", allhashtags)
      imagesObj && payload.append("images", imagesObj)
      video_fileObj && payload.append("video_file", video_fileObj)
      payload.append("text", comment)
      payload.append("title", "no title")
      await postCreate(payload, token)
      handleChange("loading", false)
      setFetchPost(true)
      setData("")
      handleChange("url_field", "")
      handleChange("is_player_view", true)
      handleChange("is_parent_view", true)
      handleChange("is_coach_view", true)
      handleChange("is_organization_view", true)
      handleChange("comments_allowed", true)
      handleChange("location", "")
      handleChange("images", null)
      handleChange("imagesObj", null)
      handleChange("text", "")
      handleChange("video_file", null)
      handleChange("video_fileObj", null)
      handleClosePost()
      enqueueSnackbar(`Post Added`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const onFileChange = event => {
    const file = event.target.files[0]
    if (file?.type?.includes("image")) {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        if (img.width > img.height) {
          setSelectedAspectRatio(2) // Index for 1.91:1 aspect ratio
        } else if (img.width === img.height) {
          setSelectedAspectRatio(1) // Index for 1:1 aspect ratio
        } else {
          setSelectedAspectRatio(0) // Index for 4:5 aspect ratio
        }
        handleChange("images", img.src)
        // handleChange("images", URL.createObjectURL(file))
        handleChange("imagesObj", file)
        handleChange("video_file", "")
        handleChange("video_fileObj", null)
      }
    } else if (file?.type?.includes("video")) {
      handleChange("images", "")
      handleChange("imagesObj", null)
      handleChange("video_file", URL.createObjectURL(file))
      handleChange("video_fileObj", file)
    }
  }

  const handleSearch = async place => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 && res1?.results[0]?.formatted_address
      var address_components = res1?.results[0]?.address_components
      let dState = ""
      let country = ""
      let city = ""
      let postal_code = ""
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name
          } else if (typ === "country") {
            country = addrComp[i]?.long_name
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange("location", place?.formatted_address)
    } catch (error) {
      alert(error?.message)
    }
  }

  const removeFile = () => {
    handleChange("images", "")
    handleChange("imagesObj", null)
    handleChange("video_file", "")
    handleChange("video_fileObj", null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const open1 = Boolean(anchorEl1)
  const id1 = open1 ? "simple-popover" : undefined
  const [data, setData] = useState('')

  const containerStyle = {
    width: selectedAspectRatio === 2 ? 300 : 336,
    height: selectedAspectRatio === 2 ? 500 : selectedAspectRatio === 1 ? 250 : 336,
    objectFit: "cover"
  }

  return (
    <Modal
      open={addPost}
      onClose={handleClosePost}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="listpool">
            <img src={ProfileDP} className={"c-pointer userprofile"} />
            {ProfileName}
          </div>
          <div className="whocanview" onClick={handleClick}>
            Who can view
            <Down className=" ml-2 downBlack" />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            classes={{ paper: "dropdown-paper" }}
            sx={{ width: 250 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <div>
              <div className="checkboxDiv mb-3">
                <Checkbox
                  checked={is_player_view}
                  onClick={() =>
                    handleChange("is_player_view", !is_player_view)
                  }
                  className="checkbox1"
                  style={{
                    marginTop: -3
                  }}
                  sx={{
                    color: "rgba(201, 208, 216, 1)",
                    "&.Mui-checked": {
                      color: COLORS.primary
                    }
                  }}
                />
                <Typography
                  variant="body2"
                  className="text_primary06 checkboxLabel"
                >
                  Player
                </Typography>
              </div>
              <div className="checkboxDiv mb-3">
                <Checkbox
                  checked={is_parent_view}
                  onClick={() =>
                    handleChange("is_parent_view", !is_parent_view)
                  }
                  className="checkbox1"
                  style={{
                    marginTop: -3
                  }}
                  sx={{
                    color: "rgba(201, 208, 216, 1)",
                    "&.Mui-checked": {
                      color: COLORS.primary
                    }
                  }}
                />
                <Typography
                  variant="body2"
                  className="text_primary06 checkboxLabel"
                >
                  Community
                </Typography>
              </div>
              <div className="checkboxDiv mb-3">
                <Checkbox
                  checked={is_coach_view}
                  onClick={() => handleChange("is_coach_view", !is_coach_view)}
                  className="checkbox1"
                  style={{
                    marginTop: -3
                  }}
                  sx={{
                    color: "rgba(201, 208, 216, 1)",
                    "&.Mui-checked": {
                      color: COLORS.primary
                    }
                  }}
                />
                <Typography
                  variant="body2"
                  className="text_primary06 checkboxLabel"
                >
                  Coach
                </Typography>
              </div>
              <div className="checkboxDiv mb-3">
                <Checkbox
                  checked={is_organization_view}
                  onClick={() =>
                    handleChange("is_organization_view", !is_organization_view)
                  }
                  className="checkbox1"
                  style={{
                    marginTop: -3
                  }}
                  sx={{
                    color: "rgba(201, 208, 216, 1)",
                    "&.Mui-checked": {
                      color: COLORS.primary
                    }
                  }}
                />
                <Typography
                  variant="body2"
                  className="text_primary06 checkboxLabel"
                >
                  Organization
                </Typography>
              </div>
            </div>
          </Popover>
        </Grid>
        <MentionTextBox
          data={data}
          setData={setData}
          border="none"
          setURL={setURL}
        />
        {url ? <Microlink url={url} /> : <></>}
        <Grid sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: 5 }}>
          <input
            type="file"
            accept="image/png,image/gif,image/jpeg,video/mp4,video/x-m4v,video/*"
            onChange={onFileChange}
            style={{ display: "none" }}
            ref={imageRef}
          />
          {images ? (
            <div className="imageView">
              <div className="crossIcon" onClick={removeFile}>
                x
              </div>
              <div className="ratioDiv" onClick={handleClick1}>
                <AspectRatioIcon />
              </div>
              <Popover
                id={id1}
                open={open1}
                anchorEl={anchorEl1}
                onClose={handleClose1}
                classes={{ paper: "dropdown-paper" }}
                sx={{ width: 250 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              >
                <div>
                  <div className="checkboxDiv3 mb-2" onClick={() => {
                    setSelectedAspectRatio(0)
                    handleClose1()
                  }}>
                    <Typography
                      variant="body2"
                      className="text_primary06"
                    >
                      1:1
                    </Typography>
                    <CropDinIcon />
                  </div>
                  <div className="checkboxDiv3 mb-2" onClick={() => {
                    setSelectedAspectRatio(1)
                    handleClose1()
                  }}>
                    <Typography
                      variant="body2"
                      className="text_primary06"
                    >
                      4:5
                    </Typography>
                    <Crop75Icon />
                  </div>
                  <div className="checkboxDiv3 mb-2" onClick={() => {
                    setSelectedAspectRatio(2)
                    handleClose1()
                  }}>
                    <Typography
                      variant="body2"
                      className="text_primary06"
                    >
                      16:9
                    </Typography>
                    <CropPortraitIcon />
                  </div>
                </div>
              </Popover>
              <img
                src={images}
                onClick={handleClickImage}
                style={containerStyle}
              // className={"c-pointer postIMG"}
              />
            </div>
          ) : video_file ? (
            <>
              <div className="crossIcon" onClick={removeFile}>
                x
              </div>
              <video width="100%" height="240" controls>
                <source src={video_file} type="video/mp4" />
                <source src={video_file} type="video/ogg" />
              </video>
            </>
          ) : (
            <div className="addImage" onClick={handleClickImage}>
              <div className="flex mt-2">
                <AddImage className="mr-2" />
                <AddVideo />
              </div>
              <div className="addpostText">Add your post</div>
              <div className="addpostDescription">
                You can upload image or video
              </div>
            </div>
          )}
          <div className="fullWidthWeb mt-2">
            <Label text={"Add Location"} />
            <AutoComplete
              apiKey={MAP_API_KEY}
              className="locationSearch"
              options={{ types: ["geocode", "establishment"] }}
              style={{ height: 40, backgroundColor: "#fff" }}
              onChange={e => handleChange("location", e?.target?.value)}
              defaultValue={location}
              onPlaceSelected={place => {
                handleSearch(place)
              }}
              placeholder=""
            />
          </div>
          <div className="fullWidthWeb mt-2">
            <Label text={"Add URL"} />
            <AppInput height={40} onChange={handleChange} name={"url_field"} />
          </div>
          <div className="fullWidthWeb mt-3">
            {url_field ? <Microlink url={url_field} /> : <></>}
          </div>
        </Grid>
        <div className="checkboxDiv2 mb-3">
          <Checkbox
            checked={comments_allowed}
            onClick={() =>
              handleChange("comments_allowed", !comments_allowed)
            }
            className="checkbox1"
            style={{
              marginTop: -3
            }}
            sx={{
              color: "rgba(201, 208, 216, 1)",
              "&.Mui-checked": {
                color: COLORS.primary
              }
            }}
          />
          <Typography
            variant="body2"
            className="text_primary06 checkboxLabel"
          >
            Allow comments on the post
          </Typography>
        </div>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Create Post"}
            loading={loading}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={100}
            height={40}
            className={"mt-5"}
            onClick={_postCreate}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default AddPost
