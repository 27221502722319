import React, { useEffect, useState } from "react"
import { HomeHeader } from "../../components"
import AvatarIcon from "../../assets/images/avatar.png"
import { acceptLink, declineLink, searchUserProfile, searchUserProfileOld } from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import DeleteIcon from "@mui/icons-material/Delete"
import { useNavigate } from "react-router-dom"

const LinkingRequest = () => {
  const token = localStorage.getItem("token")
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [state, setState] = useState({
    linkedPlayers: [],
    linkVisible: false
  })

  const { linkedPlayers, linkVisible } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    getLinkedPlayers()
  }, [linkVisible])

  const getLinkedPlayers = async () => {
    try {
      // const res = await searchUserProfile(`linked_relationships/`, token)
      let endpoint = `linked_relationships/`
      // if (id) {
      //   endpoint += `?user_id=${id}`
      // }
      const res = await searchUserProfileOld(endpoint, token)
      handleChange("linkedPlayers", res?.data)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const acceptLinkAction = async linkId => {
    try {
      const payload = {
        link_id: linkId
      }
      const res = await acceptLink(payload, token)
      enqueueSnackbar(`Linked request accepted`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("linkVisible", !linkVisible)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("linkVisible", !linkVisible)
    }
  }
  const declineLinkAction = async (linkId, state) => {
    try {
      const payload = {
        link_id: linkId
      }
      const res = await declineLink(payload, token)
      if (state === "decline") {
        enqueueSnackbar(`Linked request declined`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      } else {
        enqueueSnackbar(`Linking removed successfully`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
      handleChange("linkVisible", !linkVisible)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("linkVisible", !linkVisible)
    }
  }

  return (
    <div>
      <HomeHeader dashboard />
      <div style={{ paddingTop: "3rem" }}>
        <section className="container p-0 width60 bg-transparent">
          <div>
            <div
              className="flex-between"
              style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
            >
              <div
                className="font-bold font-24"
                style={{ color: "rgba(0, 0, 0, 0.85)" }}
              >
                Linking Request
              </div>
            </div>
            {linkedPlayers?.map((member, index) => {
              return (
                <div
                  className="parentGreyBox"
                  //   key={index}
                >
                  <div className="flex-between" style={{ width: "100%" }}>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <img
                        src={member?.parent?.picture || AvatarIcon}
                        className={"c-pointer memberprofile"}
                      />
                      <div>
                        <div
                          className="font-bold text-black font-14 d-flex"
                          style={{ gap: 10 }}
                        >
                          {member?.parent?.user?.name || "Guest"}
                        </div>
                        <div className="lightgreyColor font-12">
                          {/* {
                            member?.player?.committed_to_organization
                              ?.organization_name
                          } */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ gap: 10, alignItems: "center" }}
                    >
                      {!member?.is_confirmed ? (
                        <>
                          <div
                            className={`alert_red font-14 cursor`}
                            onClick={() => {
                              declineLinkAction(member?.id, "decline")
                            }}
                          >
                            Decline
                          </div>
                          <div
                            className={`text_green font-14 cursor`}
                            onClick={() => {
                              acceptLinkAction(member?.id)
                            }}
                          >
                            Accept
                          </div>
                        </>
                      ) : (
                        <div
                          className={`alert_red font-14 cursor`}
                          onClick={() => {
                            declineLinkAction(member?.id, "unlink")
                          }}
                        >
                          Unlink
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    </div>
  )
}

export default LinkingRequest
