import React, { useContext, useEffect, useState } from "react"
import { HomeHeader, AppButton, ScheduleTable } from "../../components"
import { Grid, IconButton } from "@mui/material"
import { ReactComponent as PlusWhite } from "../../assets/svg/PlusWhite.svg"
import { COLORS } from "../../constants"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate, useParams } from "react-router-dom"
import { getGameSchedules } from "../../api/auth"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import AppContext from "../../Context"

export default function GameSchedule({ }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const { organizationData } = useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const [state, setState] = useState({
    gameSchedule: [],
    loading: false,
    isAddNew: false,
    limit: 5,
    page: 0,
    offset: 0
  })

  const { gameSchedule, loading, limit, page, isAddNew } = state

  useEffect(() => {
    if (id) {
      getData(page)
    }
  }, [id])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const getData = async nextPage => {
    try {
      handleChange("gameSchedule", null)
      handleChange("loading", true)
      const offset = limit * nextPage
      const res = await getGameSchedules(
        `?team=${id}&limit=${limit}&offset=${offset}`,
        token
      )
      handleChange("gameSchedule", res?.data)
      handleChange("offset", offset)
      handleChange("page", nextPage)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleChangePage = (e, page) => {
    getData(page)
  }

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container pb-3 mb-0 bg-transparent">
        <Grid className="mb-3 border round-1">
          <Grid container>
            <IconButton
              aria-label="delete"
              size=""
              sx={{
                bgcolor: COLORS.white,
                borderRadius: 2,
                color: "#222"
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon color="#222" />
            </IconButton>
          </Grid>
          <Grid
            className="mt-2 mb-2 p-2"
            alignItems={"center"}
            container
            justifyContent={"space-between"}
          >
            <div className="font-20 font-bold text-black">Schedule Game</div>
            <AppButton
              width={120}
              height={35}
              onClick={() => handleChange("isAddNew", !isAddNew)}
              backgroundColor={COLORS.primary}
              fontWeight={300}
              color={COLORS.white}
              className={"min-width-remove"}
              title={
                <div
                  className="d-flex align-items-center"
                  style={{ marginLeft: -5 }}
                >
                  <PlusWhite width={20} />{" "}
                  <span className="ml-1">Add Game</span>
                </div>
              }
            />
          </Grid>
          <ScheduleTable
            isAddNew={isAddNew}
            gameSchedule={gameSchedule}
            page={page}
            limit={limit}
            handleChangeMain={handleChange}
            handleChangePage={handleChangePage}
            loading={loading}
          />
        </Grid>
      </section>
    </div>
  )
}
