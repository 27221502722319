import React from 'react';
import { Link } from 'react-router-dom';

// Sample text containing mentions

const MentionText = ({ text, id, taggedUsers, handleUserClick }) => {
    // Regular expression to detect mentions (e.g., @username)
    const mentionRegex = /(@[\w.+-]+)/g;

    // Split text by mentions and map over the parts
    const renderTextWithMentions = (text) => {
        return text?.split(mentionRegex)?.map((part, index) => {
            if (mentionRegex?.test(part)) {
                // If part matches the mention regex, return it as a clickable link
                return (
                    <span
                        key={index}
                        onClick={() => handleMentionClick(part)}
                        className='font-bold'
                        style={{ color: "#00000073", cursor: 'pointer' }}
                    >
                        {part}
                    </span>
                );
            } else {
                // Otherwise, return plain text
                return <Link
                    to={`/post/${id}`}
                    style={{ textDecoration: "none", color: "#00000073" }}
                >
                    {part}
                </Link>
            }
        });
    };

    // Handle mention click
    const handleMentionClick = (mention) => {
        const found = taggedUsers?.find(e => `@${e?.username}` === mention)
        if (found) {
            handleUserClick(found)
        }
    };

    return <div>{renderTextWithMentions(text)}</div>;
};



export default MentionText;
