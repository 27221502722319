// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useState } from "react"
import { HomeHeader, AppButton, TeamTable } from "../../components"
import {
  Grid,
} from "@mui/material"
import { ReactComponent as PlusWhite } from "../../assets/svg/PlusWhite.svg"
import { COLORS } from "../../constants"
import { useNavigate } from "react-router-dom"
import { getOrganizationTeam } from "../../api/auth"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import AppContext from "../../Context"

export default function Teams({ }) {
  const navigate = useNavigate()
  const { organizationData, isCoach, userProfile } = useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const [state, setState] = useState({
    teams: [],
    loading: false,
    limit: 5,
    page: 0,
    offset: 0,
  })

  const {
    teams,
    loading,
    limit,
    page,
  } = state

  useEffect(() => {
    if (organizationData?.id || (isCoach && userProfile?.coach?.id)) {
      getData(page)
    }
  }, [organizationData?.id])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const getData = async (nextPage) => {
    try {
      handleChange("teams", null)
      handleChange("loading", true)
      const offset = limit * nextPage
      let payload = isCoach ? `?coach__id=${userProfile?.coach?.id}&limit=${limit}&offset=${offset}` : `?organization__id=${organizationData?.id}&limit=${limit}&offset=${offset}`
      const res = await getOrganizationTeam(payload, token)
      handleChange("teams", res?.data)
      handleChange("offset", offset)
      handleChange("page", nextPage)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleChangePage = (e, page) => {
    getData(page)
  }

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container pb-3 mb-0 bg-transparent">
        <Grid className="mb-3 border round-1" >
          <Grid className="mt-2 mb-2 p-2" alignItems={'center'} container justifyContent={"space-between"}>
            <div className="font-20 font-bold text-black">{organizationData?.organization_name} Team</div>
            {
              !isCoach &&
              <AppButton width={120} height={35}
                onClick={() => navigate("/teams/add")} backgroundColor={COLORS.primary} fontWeight={300} color={COLORS.white}
                className={'min-width-remove'} title={<div className="d-flex align-items-center" style={{ marginLeft: -5 }}><PlusWhite width={20} /> <span className="ml-1">Add Team</span></div>} />
            }
          </Grid>
          <TeamTable teams={teams} id={organizationData?.user?.id} page={page} limit={limit} handleChangePage={handleChangePage} loading={loading} />
        </Grid>
      </section>
    </div>
  )
}
