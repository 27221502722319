import React from "react"

export default function HomeFooter({ showCOntactUsMobile, displayMobileHide }) {
  const openLink = link => {
    window.open(link, "_blank")
  }
  return (
    <div>
      {/* <!--Home footer--> */}
      <footer className="home_footer">
        <div className="container phm-0 maxWidthMobile">
          <div className="row mt-5 mb-5">
            <div className="col-md-7">
              <div className="text-white font-bold mb-2 font-24">About Us</div>
              <div className="text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
            <div className="text-white  col-md-4">
              <div className="text-white font-bold font-24">
                Useful Links
              </div>
              <div className="text-white footerLink d-flex col-md-12">
                <div className="text-white col-md-6">
                  <div
                    className="mt-3 font-bold c-pointer"
                  >
                    Home
                  </div>
                  <div
                    className="mt-3 font-bold c-pointer"
                  >
                    About Us
                  </div>
                  <div
                    className="mt-3 font-bold c-pointer"
                  >
                    FAQ
                  </div>
                  <div
                    className="mt-3 font-bold c-pointer"
                  >
                    Contact Us
                  </div>
                </div>
                <div className="text-white">
                  <div
                    className="mt-3 font-bold c-pointer"
                  >
                    Privacy Policy
                  </div>
                  <div
                    className="mt-3 font-bold c-pointer"
                  >
                    Terms & Conditions
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
