// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import { HomeHeader, AppButton, AppInput, Label, AdminLayout } from "../../components"
import {
  Box,
  Grid, Modal, Typography,
} from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import { COLORS } from "../../constants"
import { getError } from "../../utils/getError"
import { getUserProfile } from "../../api/auth"
import { deleteUser } from "../../api/admin"
import { ReactComponent as DeleteOrangeIcon } from '../../assets/svg/admin/deleteOrange.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: 6,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function AdminParentProfileForm({ }) {
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const [state, setState] = useState({
    name: "",
    email: "",
    phone_number: "",
    street_address: "",
    city: "",
    zip_code: "",
    selectedState: "",
    editMode: false,
    loadingDelete: false,
    visible: false,
    profileImg: null,
    profileObj: null,
    userProfile: null
  })

  const {
    name,
    email,
    phone_number,
    street_address,
    city,
    zip_code,
    selectedState,
    editMode,
    profileImg,
    userProfile,
    loadingDelete,
    visible
  } = state

  useEffect(() => {
    if (id) {
      _getProfileData()
    }
  }, [id])

  const _getProfileData = async (noLoading) => {
    try {
      handleChange('loadingData', true)
      const token = localStorage.getItem("admintoken")
      const res = await getUserProfile(id, token)
      const userProfile = res?.data
      handleChange('userProfile', userProfile)
      handleChange('name', userProfile?.name)
      handleChange('email', userProfile?.email)
      handleChange('city', userProfile?.city)
      handleChange('email', userProfile?.email)
      handleChange('street_address', userProfile?.street_address)
      handleChange('zip_code', userProfile?.zip_code)
      handleChange('selectedState', userProfile?.state)
      handleChange('loadingData', false)
    } catch (error) {
      handleChange('loadingData', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteUser = async () => {
    try {
      handleChange("loadingDelete", true)
      await deleteUser(id, token)
      handleChange("loadingDelete", false)
      navigate(-1)
      enqueueSnackbar("User is Deleted", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }


  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleOpenDelete = () => {
    handleChange("visible", true)
  }
  const handleCloseDelete = () => {
    handleChange("visible", false)
  }

  return (
    <div className="">
      <AdminLayout>
        <section className="container pb-3 mb-0 bg-transparent">
          <Grid className="mb-3" container justifyContent={"space-between"}>
            <div className="pageHeading">Profile</div>
          </Grid>
          <Grid className="mb-3 mt-4" container alignItems={'center'} justifyContent={"space-between"}>
            <div className="">
              <img
                src={profileImg || userProfile?.parent?.picture || AvatarIcon}
                className={"c-pointer profileIMG"}
              />
            </div>
            <AppButton width={100} height={35} borderRadius={5} onClick={handleOpenDelete} backgroundColor={COLORS.white} color={'#595959'} borderColor={'#D9D9D9'} className={'min-width-remove'} title={"Delete"} />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <AppInput disabled={!editMode} label={'Full Name'} height={40} onChange={handleChange} value={name} name={'name'} />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput disabled={true} label={'Email'} height={40} onChange={handleChange} value={email} name={'email'} />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput disabled={!editMode} label={'Phone number'} type={'number'} height={40} onChange={handleChange} value={phone_number} name={'phone_number'} />
            </Grid>
            <Grid item xs={12} md={3}>
              <AppInput disabled={true} label={'Street address'} height={40} onChange={handleChange} value={street_address} name={'street_address'} />
            </Grid>
            <Grid item xs={12} md={3}>
              <AppInput disabled={!editMode} label={'City'} height={40} onChange={handleChange} value={city} name={'city'} />
            </Grid>
            <Grid item xs={12} md={3}>
              <AppInput disabled={!editMode} label={'Zipcode'} height={40} onChange={handleChange} value={zip_code} name={'zip_code'} />
            </Grid>
            <Grid item xs={12} md={3}>
              <AppInput disabled={!editMode} label={'State'} height={40} onChange={handleChange} value={selectedState} name={'selectedState'} />
            </Grid>
          </Grid>
        </section>
      </AdminLayout>
      <Modal
        open={visible}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DeleteOrangeIcon className='mt-3' />
            </Grid>
            <Grid item xs={10}>
              <Typography id="modal-modal-description" className='text-black' sx={{ mt: 2 }}>
                Are you sure you want to delete this user? You won’t be able to recover it
              </Typography>
            </Grid>
          </Grid>
          <Grid className='mt-3'>
            <AppButton title={'Delete'} height={40} loading={loadingDelete} onClick={() => _deleteUser()} width={"100%"} fontWeight={'normal'} backgroundColor={COLORS.orange} color={COLORS.white} />
            <AppButton title={'Cancel'} height={40} onClick={handleCloseDelete} width={"100%"} backgroundColor={"#fff"} className={'mt-2'} color={'#595959'} borderColor={'#D9D9D9'} />
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}
