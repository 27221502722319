import React, { useEffect, useState } from "react"
import { AppButton, HomeHeader, Loader } from "../../components"
import { Grid, IconButton, Paper } from "@mui/material"
import { ReactComponent as AddPlayerIcon } from "../../assets/svg/add-player.svg"
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete.svg"
import { COLORS } from "../../constants"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate, useParams } from "react-router-dom"
import { DataGrid } from "@mui/x-data-grid"
import AppSelect from "../../components/AppSelect"
import RemovePlayer from "../../components/GeneralModal/RemoveUser"
import { ADDPLAYER, PLAYERPROFILE } from "../../constants/routes"
import { getError } from "../../utils/getError"
import {
  assignPotentialPlayer,
  getOrganizationTeam,
  getPotentialPlayers,
  getUserProfile,
  invitePotentialPlayer
} from "../../api/auth"
import { useSnackbar } from "notistack"

const handleStatusColors = status => {
  switch (status) {
    case "Invitation Pending":
      return {
        borderColor: "#FFD591",
        color: "#FA8C16",
        backgroundColor: "#FFF7E6"
      }
    case "Invitation Sent":
      return {
        borderColor: "#FFE58F",
        color: "#FAAD14",
        backgroundColor: "#FFFBE6"
      }
    case "Interested":
      return {
        borderColor: "#ADC6FF",
        color: "#2F54EB",
        backgroundColor: "#F0F5FF"
      }
    case "Committed":
      return {
        borderColor: "#B7EB8F",
        color: "#52C41A",
        backgroundColor: "#F6FFED"
      }
    case "Declined":
      return {
        color: "#F5222D",
        borderColor: "#FFA39E",
        backgroundColor: "#FFF1F0"
      }
  }
}

const handleInviteVisibility = status => {
  switch (status) {
    case "Invitation Pending":
      return false
    case "Invitation Sent":
      return false

    case "Interested":
      return true
    case "Committed":
      return true
    case "Declined":
      return false
  }
}

const PotentialPlayer = () => {
  const { organization, user } = useParams()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [removeModal, setRemoveModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState(false)
  const [state, setState] = useState({
    statsVisible: false,
    teamID: "",
    teams: [],
    loading: false,
    limitTeam: 10,
    pageTeam: 0,
    offsetTeam: 0,
    organizationData: null,
    switchTeam: null,
    potentialPlayers: null,
    inviteLoading: false,
    assignLoading: false
  })

  const {
    teams,
    loading,
    limitTeam,
    pageTeam,
    offsetTeam,
    organizationData,
    switchTeam,
    potentialPlayers,
    inviteLoading
  } = state
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: params => {
        return (
          <div
            className="text-black"
            onClick={() =>
              params?.row?.player?.user?.id &&
              navigate(
                PLAYERPROFILE?.replace(":id", params?.row?.player?.user?.id)
              )
            }
          >
            <img
              src={params?.row?.player?.picture}
              className={"c-pointer userprofile"}
            />
            {params?.row?.name || params?.row?.player?.user?.name}
          </div>
        )
      }
    },

    {
      field: "primary_position",
      headerName: "Primary Position",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
      valueGetter: params =>
        `${params?.row?.primary_position ||
        params?.row?.player?.first_position_play?.name ||
        ""
        }`
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: params => {
        return (
          <div
            style={{
              ...(handleStatusColors(params?.row?.status) || {}),
              borderWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              borderStyle: "solid"
            }}
          >
            {params?.row?.status}
          </div>
        )
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 300,
      renderCell: params => (
        <Grid container spacing={2}>
          <Grid item>
            {Boolean(handleInviteVisibility(params?.row?.status)) ? (
              <AppSelect
                data={teams?.results?.map(v => {
                  return {
                    title: v.team_name,
                    value: v.id
                  }
                })}
                placeholder={"Assign Team"}
                value={params?.row?.player?.commited_to_team?.id}
                onChange={e => handleAssign(e.target.value, [params?.row?.id])}
              />
            ) : (
              <AppButton
                color={"#222222"}
                fontWeight={400}
                height={35}
                variant="outlined"
                borderColor={"#D9D9D9"}
                title={"Invite"}
                onClick={() => handleInvite([params?.row?.id])}
              />
            )}
          </Grid>
          <Grid item>
            <AppButton
              onClick={() => setRemoveModal([params.row.id])}
              height={30}
              className={"min-width-remove"}
              title={"Remove Player"}
              color={"#FF4D4F"}
              backgroundColor={"transparent"}
              fontWeight={400}
            />
          </Grid>
        </Grid>
      )
    }
  ]

  const getTeams = async (nextPage, load = true) => {
    try {
      load && handleChange("teams", null)
      load && handleChange("loading", true)
      const offset = limitTeam * nextPage
      const token = localStorage.getItem("token")
      const res = await getOrganizationTeam(
        `?organization__id=${organization}&limit=${limitTeam}&offset=${offsetTeam}`,
        token
      )
      const orgRes = await getUserProfile(user, token)
      const potentialPlayersData = await getPotentialPlayers(token)
      handleChange("organizationData", orgRes?.data?.organization)
      handleChange("potentialPlayers", potentialPlayersData?.data)
      handleChange("teams", res?.data)
      handleChange("offset", offset)
      handleChange("pageTeam", nextPage)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const getProfileData = async () => {
    try {
      const token = localStorage.getItem("token")
      handleChange("loading", true)
      const res = await getUserProfile(user, token)
      handleChange("organizationData", res?.data?.organization)
      // handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleInvite = async (ids = []) => {
    try {
      handleChange("inviteLoading", true)
      const token = localStorage.getItem("token")

      await invitePotentialPlayer({ potential_players_ids: ids }, token)

      getTeams(null, false)
      enqueueSnackbar(`User has been invited successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } finally {
      handleChange("inviteLoading", false)
    }
  }

  const handleAssign = async (teamId, rows) => {
    try {
      handleChange("assignLoading", true)
      const token = localStorage.getItem("token")

      await assignPotentialPlayer(
        { potential_players_ids: rows, team_id: teamId },
        token
      )

      getTeams(null, false)
      enqueueSnackbar(`User has been assigned to team successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } finally {
      handleChange("assignLoading", false)
    }
  }

  useEffect(() => {
    getTeams()
    getProfileData()
  }, [])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleSwitchTeam = e => {
    handleChange("switchTeam", e)
  }

  const handleCloseModal = () => {
    getTeams(null, false)
    setRemoveModal(false)
  }

  return (
    <div>
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <section className="container p-0 pt-3 bg-transparent">
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <Grid container spacing={3} alignItems={"center"}>
                    <Grid item>
                      <IconButton
                        aria-label="delete"
                        size=""
                        sx={{
                          bgcolor: COLORS.white,
                          borderRadius: 2,
                          color: "#222"
                        }}
                        onClick={() => navigate(-1)}
                      >
                        <ArrowBackIcon color="#222" />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <p className="font-medium font-18">
                        {organizationData?.organization_name || ""}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <AppButton
                    color={COLORS.white}
                    fontWeight={400}
                    backgroundColor={COLORS.primary}
                    height={35}
                    title={
                      <div>
                        <AddPlayerIcon
                          style={{ marginBottom: 3, marginRight: 5 }}
                        />
                        Add Player
                      </div>
                    }
                    onClick={() =>
                      navigate(ADDPLAYER?.replace(":id", organization))
                    }
                  />
                </Grid>
              </Grid>

              <Paper
                variant="outlined"
                sx={{ borderColor: "#0000000F", mt: 3 }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ paddingLeft: 1, paddingRight: 1, minHeight: 80 }}
                >
                  <Grid item>
                    <p className="potentialPlayer">
                      Potential Players
                    </p>
                  </Grid>
                  <Grid item alignItems={"flex-end"}>
                    <Grid
                      container
                      spacing={1}
                      sx={{ p: 1 }}
                      alignContent={"flex-end"}
                      justifyItems={"flex-end"}
                    >
                      <Grid item sx={{ maxWidth: { xs: 130, sm: 150 }, marginLeft: { xs: -2, sm: 0 } }}>
                        <AppSelect
                          // maxWidth={{sx:2,md:"auto"}}
                          data={teams?.results?.map(v => {
                            return {
                              title: v.team_name,
                              value: v.id
                            }
                          })}
                          value={switchTeam}

                          onChange={e =>
                            handleAssign(e.target.value, selectedRows)
                          }
                          placeholder={"Assign Team"}
                        />
                      </Grid>
                      <Grid item>
                        <AppButton
                          color={"#222222"}
                          fontWeight={400}
                          height={35}
                          variant="outlined"
                          borderColor={"#D9D9D9"}
                          title={<div>Invite</div>}
                          onClick={() => handleInvite(selectedRows)}
                        />
                      </Grid>
                      <Grid item>
                        <AppButton
                          color={"#FF4D4F"}
                          fontWeight={400}
                          height={35}
                          variant="outlined"
                          borderColor={"#FF4D4F"}
                          title={
                            <div>
                              <DeleteIcon
                                style={{ marginBottom: 3, marginRight: 7 }}
                              />
                              Remove
                            </div>
                          }
                          onClick={() =>
                            setRemoveModal(
                              selectedRows?.length ? selectedRows : false
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <DataGrid
                      onRowSelectionModelChange={e => setSelectedRows(e)}
                      rows={potentialPlayers?.results || []}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10
                          }
                        }
                      }}
                      pageSizeOptions={[10]}
                      checkboxSelection
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnMenu
                      style={{
                        borderWidth: 1,
                        minHeight: 150
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </section>
          </div>
        </>
      )}
      <RemovePlayer
        type="potential"
        visible={removeModal}
        handleClose={handleCloseModal}
      />
    </div>
  )
}

export default PotentialPlayer
