import React, { useContext, useState } from 'react'
import { AppButton, AppInput } from '..'
import { Box, Grid, Modal, Popover, Typography, } from '@mui/material'
import { COLORS } from '../../constants';
import { ReactComponent as Cross } from '../../assets/svg/Cross.svg'
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import AppContext from '../../Context';
import { organizationReview } from '../../api/auth';
import { getError } from '../../utils/getError';
import { useSnackbar } from 'notistack';


function AddReview({ getProfileData, organizationData, visible, handleClose }) {
    const { hashtagsList } = useContext(AppContext)
    const { enqueueSnackbar } = useSnackbar()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        borderRadius: 6,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [state, setState] = useState({
        dateGame: '',
        selectedTag: []
    })
    const {
        dateGame,
        selectedTag
    } = state

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    const _organizationReview = async () => {
        try {
            handleChange('loading', true)
            const token = localStorage.getItem("token")
            const payload = {
                organization: organizationData?.id,
                hashtags: selectedTag
            }
            await organizationReview(payload, token)
            getProfileData()
            handleChange('loading', false)
            handleChange('selectedTag', [])
            handleClose()
            enqueueSnackbar(`Review Added`, {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        } catch (error) {
            handleChange('loading', false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    return (
        <Modal
            open={visible}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                    <div className='font-24 font-500 mb-2 greyColor'>Select Hashtag</div>
                    <div className='font-14 greyColor'>Select a hashtag to give a review. You can not review again after you confirm.</div>
                </Grid>
                <Grid container spacing={2}>
                    {
                        hashtagsList.map((item, index) => (
                            <Grid item xs={4} key={index}>
                                <AppButton title={<div className='font-12'>{item?.name}</div>} type={'date'} backgroundColor={selectedTag?.some(e => e === item?.id) ? '#0059C1' : '#E6F6FF'}
                                    color={selectedTag?.some(e => e === item?.id) ? '#fff' : '#000000D9'}
                                    fontWeight={300}
                                    height={40} onClick={() => {
                                        if (selectedTag?.some(e => e === item?.id)) {
                                            const removed = selectedTag?.filter(e => e !== item?.id)
                                            handleChange('selectedTag', removed)
                                        } else if (selectedTag?.length === 3) {
                                            alert("You can't select more than 3 tags")
                                            return
                                        } else {
                                            handleChange('selectedTag', [...selectedTag, item?.id])
                                        }
                                    }}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid container>
                    <AppButton title={'Confirm'} disabled={selectedTag.length === 0} backgroundColor={COLORS.primary} color={COLORS.white} width={'100%'} height={40} className={'mt-5'} onClick={_organizationReview} />
                </Grid>
            </Box>
        </Modal>
    )
}

export default AddReview