// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import {
  HomeFooter,
  HomeHeader,
  AppButton,
  MainFooter,
  ContactUs,
  AppInput,
  AddStats,
  Label
} from "../../components"
import { Element } from "react-scroll"
import { Checkbox, Grid, Typography } from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import AUUTeam from "../../assets/images/AUUTeam.png"
import { ReactComponent as Tiktok } from "../../assets/svg/social/Tiktok.svg"
import { ReactComponent as Facebook } from "../../assets/svg/social/Facebook.svg"
import { ReactComponent as Insta } from "../../assets/svg/social/Insta.svg"
import { ReactComponent as Twitter } from "../../assets/svg/social/Twitter.svg"
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg"
import { ReactComponent as Camera } from "../../assets/svg/Camera.svg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import { getFormattedDate } from "../../utils/dateUtils"
import { COLORS, MAP_API_KEY } from "../../constants"
import AutoComplete from "react-google-autocomplete"
import { getError } from "../../utils/getError"
import { getUserProfile, updateUserProfile } from "../../api/auth"

export default function ParentProfileForm({ }) {
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  console.log(id, "id")
  const location = useLocation()
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const { _getProfile, sports, _getSports } = useContext(AppContext)
  const hiddenFileInput = useRef(null)
  const [state, setState] = useState({
    userProfile: [],
    previousUsername: "",
    username: "",
    bio: "",
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    street_address: "",
    city: "",
    zip_code: "",
    selectedState: "",
    editMode: false,
    loading: false,
    profileImg: null,
    profileObj: null,
    main_sport: "",
  })

  const {
    userProfile,
    previousUsername,
    username,
    bio,
    name,
    first_name,
    last_name,
    email,
    phone_number,
    street_address,
    city,
    zip_code,
    selectedState,
    editMode,
    profileImg,
    profileObj,
    loading,
    main_sport
  } = state
  useEffect(() => {
    _getProfile()
    getProfileData()
    _getSports()
    if (location?.pathname === `/parent-profile/${userProfile?.id}/add`) {
      handleChange("editMode", true)
    }
  }, [location, id])
  const getProfileData = async () => {
    try {
      handleChange("loading", true)
      const res = await getUserProfile(id, token)
      handleChange("userProfile", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  useEffect(() => {
    if (userProfile) {
      handleChange("main_sport", userProfile?.parent?.main_sport?.id)
      handleChange("previousUsername", userProfile?.parent?.username)
      handleChange("username", userProfile?.parent?.username)
      handleChange("bio", userProfile?.parent?.bio)
      handleChange("name", userProfile?.name)
      handleChange("first_name", userProfile?.first_name)
      handleChange("last_name", userProfile?.last_name)
      handleChange("email", userProfile?.email)
      handleChange("city", userProfile?.city)
      handleChange("email", userProfile?.email)
      handleChange("street_address", userProfile?.street_address)
      handleChange("phone_number", userProfile?.phone_number)
      handleChange("zip_code", userProfile?.zip_code)
      handleChange("selectedState", userProfile?.state)
    }
  }, [userProfile])
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const onFileChange = event => {
    handleChange("profileImg", URL.createObjectURL(event.target.files[0]))
    handleChange("profileObj", event.target.files[0])
  }

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      const payload = new FormData()
      let fullName = first_name + " " + last_name
      if (username !== previousUsername) {
        payload.append("parent.username", username)
      }
      bio && payload?.append("parent.bio", bio)
      name && payload?.append("name", first_name + last_name)
      first_name && payload?.append("first_name", first_name)
      last_name && payload?.append("last_name", last_name)
      // email && (payload?.append("email", email))
      main_sport && payload?.append("parent.main_sport", main_sport)
      profileObj && payload?.append("parent.picture", profileObj)
      phone_number && payload?.append("phone_number", phone_number)
      street_address && payload?.append("street_address", street_address)
      city && payload?.append("city", city)
      zip_code && payload?.append("zip_code", zip_code)
      selectedState && payload?.append("state", selectedState)
      payload?.append("name", fullName)
      if (username == null || username == "") {
        alert("User Name is required")
        handleChange("loading", false)
        handleChange("username", userProfile?.parent?.username)
      } else if (first_name == "") {
        alert("First Name is required")
        handleChange("loading", false)
        handleChange("first_name", userProfile?.first_name)
      } else if (last_name == "") {
        alert("Last Name is required")
        handleChange("loading", false)
        handleChange("last_name", userProfile?.last_name)
      } else {
        await updateUserProfile(userProfile?.id, payload, token)
        _getProfile(userProfile?.id)
        handleChange("loading", false)
        handleChange("editMode", false)
        navigate("/dashboard")
        enqueueSnackbar(
          `Parent profile has been ${location?.pathname === "/parent-profile/add" ? "created" : "updated"
          }`,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            }
          }
        )
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }
  const handleSearch = async place => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 && res1?.results[0]?.formatted_address
      var address_components = res1?.results[0]?.address_components
      let dState = ""
      let country = ""
      let city = ""
      let postal_code = ""
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp?.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name
          } else if (typ === "country") {
            country = addrComp[i]?.long_name
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange("latitude", place?.geometry.location?.lat())
      handleChange("longitude", place?.geometry.location?.lng())
      handleChange("address", address)
      handleChange("city", city)
      handleChange("country", country)
      handleChange("selectedState", dState)
      handleChange("zip_code", postal_code)
    } catch (error) {
      alert(error?.message)
    }
  }
  const listSports = sports?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ))
  const option4 = <option value={""}>Select Sport</option>
  const sportsOptions = [option4, ...listSports]

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container pb-3 mb-0 bg-transparent">
        <Grid className="mb-3" container justifyContent={"space-between"}>
          <div className="pageHeading">Profile</div>
        </Grid>
        <Grid
          className="mb-3 mt-4"
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <div className="">
            <img
              src={profileImg || userProfile?.parent?.picture || AvatarIcon}
              className={"c-pointer profileIMG"}
            />
            <input
              ref={hiddenFileInput}
              type="file"
              accept="image/*"
              onChange={onFileChange}
              style={{ display: "none" }}
            />

            <AppButton
              title={<Camera />}
              onClick={handleClick}
              backgroundColor={"#FAFAFA"}
              borderColor={"#0000000F"}
              width={30}
              borderRadius={30}
              height={30}
              className={"min-width-remove camIcon"}
            />
          </div>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"User Name"}
              height={40}
              onChange={handleChange}
              value={username}
              name={"username"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"First Name"}
              height={40}
              onChange={handleChange}
              value={first_name}
              name={"first_name"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"Last Name"}
              height={40}
              onChange={handleChange}
              value={last_name}
              name={"last_name"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"Sports"}
              select
              selectOptions={sportsOptions}
              height={40}
              onChange={handleChange}
              value={main_sport}
              name={"main_sport"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={true}
              label={"Email"}
              height={40}
              onChange={handleChange}
              value={email}
              name={"email"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"Phone number"}
              type={"number"}
              height={40}
              onChange={handleChange}
              value={phone_number}
              name={"phone_number"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {editMode ? (
              <>
                <Label text={"Street Address"} />
                <AutoComplete
                  apiKey={MAP_API_KEY}
                  className="locationSearch"
                  options={{ types: ["geocode", "establishment"] }}
                  style={{ height: 40, backgroundColor: "#fff" }}
                  onChange={e =>
                    handleChange("street_address", e?.target?.value)
                  }
                  defaultValue={street_address}
                  onPlaceSelected={place => {
                    handleSearch(place)
                  }}
                  placeholder=""
                />
              </>
            ) : (
              <AppInput
                disabled={true}
                label={"Street address"}
                height={40}
                onChange={handleChange}
                value={street_address}
                name={"street_address"}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"City"}
              height={40}
              onChange={handleChange}
              value={city}
              name={"city"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"Zipcode"}
              height={40}
              onChange={handleChange}
              value={zip_code}
              name={"zip_code"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"State"}
              height={40}
              onChange={handleChange}
              value={selectedState}
              name={"selectedState"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Label fontNormal text="Bio" className="mt-3" />
            <textarea
              name="bio"
              style={{
                width: "100%",
                display: "flex",
                borderRadius: 8,
                backgroundColor: "#fff",
                color: "#000",
                height: 150,
                justifyContent: "space-between",
                border: "1px solid #D9D9D9"
              }}
              placeholder="write a description"
              multiple={true}
              value={bio}
              onChange={e => {
                handleChange("bio", e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 3 }}>
          <AppButton
            onClick={() => handleChange("editMode", false)}
            title={"Cancel"}
            className={"mr-4"}
            color={"#000000D9"}
            fontWeight={300}
            borderColor={"#D9D9D9"}
            width={100}
          />
          <AppButton
            title={"Save"}
            color={COLORS.white}
            fontWeight={300}
            backgroundColor={COLORS.primary}
            width={100}
            onClick={handleSubmit}
            loading={loading}
          />
        </Grid>
      </section>
    </div>
  )
}
