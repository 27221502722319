import React, { useState } from "react"
import { AppButton } from ".."
import { Box, Grid, Modal } from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import {
  deletePotentialPlayers,
  deleteTeamPlayer,
  leaveGroup,
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"

function RemovePlayer({ visible, handleClose, type, teamPlayers }) {
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [state, setState] = useState({
    loading: false
  })
  const { loading } = state

  const handleCloseModal = () => {
    setState({ loading: false })
    handleClose(false)
  }

  const handleSubmit = async () => {
    try {
      setState({ loading: true })
      const token = localStorage.getItem("token")

      if (type === "potential") {
        await deletePotentialPlayers({ potential_players_ids: visible }, token)
      } else {
        visible?.forEach(async element => {
          if (element && teamPlayers?.results?.some(e => e?.id === element)) {
            const foundTeam = teamPlayers?.results?.find(e => e?.id === element)
            if (foundTeam?.team?.chat_channel) {
              const payload = {
                channel: foundTeam?.team?.chat_channel,
                users: [foundTeam?.player?.user?.id]
              }
              await leaveGroup(payload)
            }
          }
        });
        await deleteTeamPlayer({ team_players_ids: visible }, token)
      }

      enqueueSnackbar(`User has been removed successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleClose(false)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } finally {
      setState({ loading: false })
    }
  }

  return (
    <Modal
      open={visible}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-20 font-medium greyColor">Remove Player</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross style={{ marginTop: -5 }} />}
            onClick={() => handleClose(false)}
          />
        </Grid>
        <p>Are you sure you want to remove player from this team?</p>

        <Grid container spacing={2} justifyContent={"flex-end"} sx={{ mt: 1 }}>
          <Grid item>
            <AppButton
              title={"Cancel"}
              color={COLORS.white}
              fontWeight={400}
              backgroundColor={"#595959"}
              height={45}
              width={"80px"}
              onClick={handleCloseModal}
            />
          </Grid>
          <Grid item>
            <AppButton
              title={"Remove"}
              backgroundColor={"#FF4D4F"}
              color={COLORS.white}
              fontWeight={400}
              height={45}
              width={"100px"}
              onClick={handleSubmit}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default RemovePlayer
