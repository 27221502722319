export const checkSportIsBasketball = (sports, id) => {
    return sports?.some(e => e?.id?.toString() === id?.toString() && e?.name?.toLowerCase() === "basketball")
}

export const gotoLink = link => {
    let url = link
    if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url
    }
    window.open(url, "_blank")
}

export const ftValues = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
]

export const inValues = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
]

export const EMOJI_ICONS = [
    { key: "smile", value: '😃' },
    { key: "thumbs-up", value: '👍' },
    { key: "thumbs-down", value: '👎' },
    { key: "high-10", value: '🙌' },
    { key: "hands", value: '🙏' },
    { key: "heart", value: '❤️' },
]


export const checkURL = (name, id) => {
    switch (name) {
        case "Volleyball":
            return `api/v1/volleyball-player-stat-entry/?player=${id}&limit=1000`
        case "Football":
            return `api/v1/football-player-stat-entry/?player=${id}&limit=1000`
        default:
            return `api/v1/player-stat-entry/?player=${id}&limit=1000`
    }
}

export const statsSummaryBasketball = [
    {
        key: "GP",
        value: "games_played"
    },
    {
        key: "PPG",
        value: "points_per_game"
    },
    {
        key: "RPG",
        value: "rebounds_per_game"
    },
    {
        key: "APG",
        value: "assists_per_game"
    },
    {
        key: "FG%",
        value: "field_goal_percentage"
    },
    {
        key: "3P FG%",
        value:
            "three_point_field_goal_percentage"
    },
    {
        key: "FT%",
        value: "free_throw_percentage"
    },
    {
        key: "SPG",
        value: "steals_per_game"
    },
    {
        key: "BPG",
        value: "blocks_per_game"
    },
    {
        key: "Fouls",
        value: "fouls_per_game"
    },
    {
        key: "Turnover",
        value: "turnovers_per_game"
    },
    { key: "EFF", value: "eff" }
]

export const statsSummaryVolleyball = [
    { key: "Attacks", value: "total_attempts" },
    { key: "Kills", value: "kills" },
    { key: "Attack Errors", value: "attack_errors" },
    { key: "Attack %", value: "attack_percentage", isPercentage: true },
    { key: "Assists", value: "assists" },
    { key: "Assist %", value: "assist_percentage", isPercentage: true },
    { key: "Digs", value: "digs" },
    { key: "Blocks", value: "total_blocks" },
    { key: "Service Aces", value: "service_aces" },
    { key: "Service Errors", value: "service_errors" },
    { key: "Passing Rating", value: "passing_rating" },
    { key: "Reception Attempts", value: "reception_attempts" },
    { key: "Reception Error", value: "reception_errors" },
    { key: "Reception %", value: "reception_percentage", isPercentage: true },
    { key: "Attack Efficiency", value: "attack_efficiency" },
    { key: "Passing Efficiency", value: "passing_efficiency" },
]


export const statsSummaryFootball = [
    { key: "Touchdowns", value: "total_touchdowns" },
    { key: "Catch %", value: "catch_percentage", isPercentage: true },
    { key: "Field Goals Attempted", value: "total_field_goals_attempted" },
    { key: "Field Goal %", value: "field_goal_percentage", isPercentage: true },
    { key: "Yards", value: "total_yards" },
    { key: "Passing Completions", value: "total_passing_completions" },
    { key: "Passing Touchdowns", value: "total_passing_touchdowns" },
    { key: "QB Interceptions", value: "total_qb_interceptions" },
    { key: "Rushing Yards", value: "total_rushing_yards" },
    { key: "Rushing Attempts", value: "total_rushing_attempts" },
    { key: "Rushing Touchdowns", value: "total_rushing_touchdowns" },
    { key: "Targets", value: "total_targets" },
    { key: "Receptions", value: "total_receptions" },
    { key: "Receiving Yards", value: "total_receiving_yards" },
    { key: "Yards Per Carry", value: "total_yards_per_carry" },
    { key: "Tackles", value: "total_tackles" },
    { key: "Passing Yards", value: "total_passing_yards" },
    { key: "Field Goals Made", value: "total_field_goals_made" },
    { key: "Fumble Recoveries", value: "total_fumble_recoveries" },
    { key: "Interceptions", value: "total_interceptions" },
    { key: "Sacks", value: "total_sacks" },
    { key: "Forced Fumbles", value: "total_forced_fumbles" },
]


export const basketballStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Points", value: "points_scored" },
    { key: "Assists", value: "assists" },
    { key: "Rebounds", value: "rebounds" },
    { key: "Steals", value: "steals" },
    { key: "Blocks", value: "blocks" },
    { key: "FGM", value: "field_goals_made" },
    { key: "FGA", value: "field_goals_attempted" },
    { key: "3P FGM", value: "three_point_field_goals_made" },
    { key: "3P FGA", value: "three_point_field_goals_attempted" },
    { key: "FTM", value: "free_throws_made" },
    { key: "FTA", value: "free_throws_attempted" },
    { key: "Turnovers", value: "turnovers" },
    { key: "Fouls", value: "fouls" },
    { key: "Actions", value: "Basketball" },
]


export const volleyballStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Attacks", value: "total_attempts" },
    { key: "Kills", value: "kills" },
    { key: "Attack Errors", value: "attack_errors" },
    { key: "Assists", value: "assists" },
    { key: "Digs", value: "digs" },
    { key: "Blocks", value: "blocks" },
    { key: "Service Aces", value: "service_aces" },
    { key: "Service Errors", value: "service_errors" },
    { key: "Passing Rating", value: "passing_rating" },
    { key: "Set Attempts", value: "set_attempts" },
    { key: "Set Errors", value: "set_errors" },
    { key: "Reception Errors", value: "reception_errors" },
    { key: "Block Assists", value: "block_assists" },
    { key: "Solo Blocks", value: "block_solos" },
    { key: "Reception Attempts", value: "reception_attempts" },
    { key: "Passing Efficiency", value: "passing_efficiency" },
    { key: "Actions", value: "Volleyball" },
]

export const footballStatsHead = [
    { key: "Date", value: "date_of_game" },
    { key: "Touchdowns", value: "touchdowns" },
    { key: "Field Goals Attempted", value: "field_goals_attempted" },
    { key: "Yards", value: "yards" },
    { key: "Passing Completions", value: "passing_completions" },
    { key: "Passing Touchdowns", value: "passing_touchdowns" },
    { key: "QB Interceptions", value: "qb_interceptions" },
    { key: "Rushing Yards", value: "rushing_yards" },
    { key: "Rushing Attempts", value: "rushing_attempts" },
    { key: "Rushing Touchdowns", value: "rushing_touchdowns" },
    { key: "Targets", value: "targets" },
    { key: "Receptions", value: "receptions" },
    { key: "Receiving Yards", value: "receiving_yards" },
    { key: "Yards Per Carry", value: "yards_per_carry" },
    { key: "Tackles", value: "tackles" },
    { key: "Passing Yards", value: "passing_yards" },
    { key: "Field Goals Made", value: "field_goals_made" },
    { key: "Fumble Recoveries", value: "fumble_recoveries" },
    { key: "Interceptions", value: "interceptions" },
    { key: "Sacks", value: "sacks" },
    { key: "Forced Fumbles", value: "forced_fumbles" },
    { key: "Actions", value: "football" },
]